<template>
    <div class="mb-3">
        <div v-if="header" class="d-flex align-items-center fw-bold border-bottom pb-2 mb-2">
            <Icon :name="headerIcon" v-if="headerIcon" class="me-2" /> {{ header }}
        </div>
        <table class="table table-sm table-borderless">
            <tbody>
            <tr v-for="(prop, index) in properties" :key="index">
                <td class="fw-semibold text-muted">{{ prop.name }}</td>
                <td class="text-end">
                    <span v-if="prop.type === 'date'" class="link-text" :title="formatDate(prop.value)">{{ fromNowDate(prop.value) }}</span>
                    <span v-else-if="prop.type === 'flag'">{{ getCountry(prop.value) }} {{ getFlag(prop.value) }}</span>
                    <span v-else-if="prop.type === 'lang'">{{ getLanguage(prop.value) }}</span>
                    <a v-else-if="prop.type === 'link'" :href="prop.link" target="_blank" class="link-text text-decoration-none">{{ prop.value }}</a>
                    <span v-else-if="prop.type === 'id'" @click="copyId(prop.value)" class="text-primary cursor-pointer">{{ prop.value }}</span>
                    <span v-else>{{ prop.value }}</span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Icon from "@/controls/icon.vue";
import moment from "moment";

export default {
    name: "PropertiesTable",
    components: { Icon },
    props: {
        properties: { type: Array, required: true },
        header: { type: String, default: "" },
        headerIcon: { type: String, default: "" }
    },
    methods: {
        formatDate(ts) {
            return ts ? moment(ts).format("MMM D, YYYY HH:mm") : "";
        },
        fromNowDate(ts) {
            return ts ? moment(ts).fromNow() : "";
        },
        copyId(id) {
            navigator.clipboard.writeText(id).then(() => console.log("ID copied"));
        },
        getLanguage(code) {
            return this.countries[code.toLowerCase()]?.language || "";
        },
        getCountry(code) {
            return this.countries[code.toLowerCase()]?.country || code;
        },
        getFlag(code) {
            return this.countries[code.toLowerCase()]?.flag || "";
        }
    },
    computed: {
        countries() {
            return {
                de: { language: "German", country: "Germany", flag: "🇩🇪" },
                en: { language: "English", country: "UK", flag: "🇬🇧" },
                ru: { language: "Russian", country: "Russia", flag: "🇷🇺" },
                es: { language: "Spanish", country: "Spain", flag: "🇪🇸" },
                fr: { language: "French", country: "France", flag: "🇫🇷" },
                it: { language: "Italian", country: "Italy", flag: "🇮🇹" },
                ja: { language: "Japanese", country: "Japan", flag: "🇯🇵" },
                nl: { language: "Dutch", country: "Netherlands", flag: "🇳🇱" },
                pt: { language: "Portuguese", country: "Portugal", flag: "🇵🇹" },
                ar: { language: "Arabic", country: "Saudi Arabia", flag: "🇸🇦" },
                ko: { language: "Korean", country: "South Korea", flag: "🇰🇷" },
                tr: { language: "Turkish", country: "Turkey", flag: "🇹🇷" },
                zh: { language: "Chinese", country: "China", flag: "🇨🇳" },
                pl: { language: "Polish", country: "Poland", flag: "🇵🇱" },
                lv: { language: "Latvian", country: "Latvia", flag: "🇱🇻" }
            };
        }
    }
};
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.link-text {
    text-decoration: none;
    border-bottom: 1px dashed currentColor;
    padding-bottom: 1px;
}

.text-end {
    text-align: right !important;
    max-width: 330px;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}
</style>