<template>
    <div class="p-3">
        <h5 class="mb-2">Missions</h5>
        <div class="d-flex flex-wrap gap-2">
            <div
                v-for="(mission, index) in missions"
                :key="index"
                class="mission-card text-center p-3 rounded shadow-sm"
                :class="getMissionStatusClass(mission)"
                style="pointer-events: none;">
                <img :src="getMissionImage(mission.name)" class="mission-image mb-2" :alt="mission.name" />
                <div :title="mission.type" class="fw-bold fs-6">{{ formatName(mission.name) }}</div>
                <small v-if="mission.started" class="d-block">From {{ formatDate(mission.started) }}</small>
                <small v-if="mission.lastRemoved" class="d-block">To {{ formatDate(mission.lastRemoved) }}</small>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import utils from "@/utils.js";

export default {
    name: "MissionsProgress",
    props: {
        missions: {
            type: Array,
            required: true
        }
    },
    methods: {
        formatDate(timestamp) {
            return moment(timestamp).format("DD MMM YYYY");
        },
        getMissionImage(name) {
            return utils.getMissionUrl(name);
        },
        formatName(name) {
            return name && name.charAt(0).toUpperCase() + name.slice(1);
        },
        getMissionStatusClass(mission) {
            if (mission.started && !mission.lastRemoved) return "bg-primary text-white active-mission";
            if (mission.lastRemoved) return "bg-secondary text-white completed-mission";
            return "bg-dark text-light pending-mission";
        }
    }
};
</script>

<style scoped>
.mission-card {
    width: 166px;
    text-align: center;
    transition: transform 0.2s ease-in-out;
    border-radius: 12px;
    padding: 8px;
}
.mission-image {
    width: 134px;
    height: 134px;
    object-fit: contain;
}
.active-mission {
    background-color: #0d6efd;
    color: white;
}
.completed-mission {
    background-color: #6c757d;
    color: white;
    opacity: 0.7;
}
.pending-mission {
    background-color: #343a40;
    color: white;
    opacity: 0.5;
}
</style>
