/**
 * Created by Andrey Popov on 12/9/20.
 */

import { createApp } from "vue";
import axios from "axios";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import store from "./store";
import App from "./router/app.vue";
import router from "./router/index";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import "bootstrap/dist/css/bootstrap.min.css";

createApp(App).use(PerfectScrollbar)
    .use(store).use(router)
    .mount("#app");

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST, PATCH, PUT, DELETE, OPTIONS";
axios.defaults.headers.common["Access-Control-Allow-Headers"] = "Origin, Content-Type, X-Auth-Token";
