<template>
    <div class="dropdown" @mouseenter="openDropdown" @mouseleave="closeDropdown">
        <slot></slot>
        <div v-if="isOpen" class="dropdown-menu">
            <slot name="dropdown"></slot>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    setup() {
        const isOpen = ref(false);

        const openDropdown = () => {
            isOpen.value = true;
        };

        const closeDropdown = () => {
            isOpen.value = false;
        };

        return { isOpen, openDropdown, closeDropdown };
    },
};
</script>

<style scoped>
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: white; /* Ensure it has a solid background */
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
    min-width: 150px;
    z-index: 1000; /* Ensure it appears above other elements */
}

.dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
    background: white; /* Ensure each item has a background */
}

.dropdown-item:hover {
    background: #f0f0f0;
}
</style>