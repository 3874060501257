/**
 * Created by Andrey Popov on 12/29/20.
 */

import moment from "moment";
import iso3311a2 from "iso-3166-1-alpha-2";
import config from "./config";

const DIALOGUE_ID_SEPARATOR = "___DIALOGUE_ID_SEPARATOR___";

const createDialogueId = function (game, dialogueId) {
    return game + DIALOGUE_ID_SEPARATOR + dialogueId;
};

const groupDialogueIdsByGames = function (dialogueIds) {
    var map = new Map();
    dialogueIds.forEach(function (dialogueId) {
        var game = dialogueId.split(DIALOGUE_ID_SEPARATOR)[0];
        dialogueId = dialogueId.split(DIALOGUE_ID_SEPARATOR)[1];
        if (map.has(game)) {
            map.get(game).push(dialogueId);
        } else {
            map.set(game, [dialogueId]);
        }
    });
    return map;
};

const formatDay = function (str) {
    return moment(str).format("DD.MM.yyyy");
};

const formatDate = function (str, short) {
    if (short) {
        return moment(str).format("HH:mm DD MMM yyyy");
    }
    return moment(str).format("HH:mm DD MMM YYYY") + " (" + moment(str).fromNow() + ")";
};

const hasPropValues = function (userCard, property) {
    if (!userCard) {
        return false;
    }

    if (property.collection && userCard[property.collection]) {
        var collection = userCard[property.collection];
        return collection && collection.length > 0;
    }

    return property.isAction || !property.path || userCard[property.path.split(".")[0]] !== undefined;
};

const getPropValues = function (userCard, property) {
    var collection = [userCard];
    if (property.collection && userCard[property.collection]) {
        collection = userCard[property.collection] || [];
    }

    return collection.map((propValue) => {
        var result = propValue;
        if (property.path) {
            if (property.path.indexOf(".") !== -1) {
                property.path.split(".").forEach(function (pathPart) {
                    result = JSON.parse(result[pathPart]);
                });
            } else {
                result = result[property.path] || "-";
            }
        }
        return result;
    });
};

const getGameInfoPropValues = function (userCard, property) {
    var result = getPropValues(userCard, property);
    if (result.length === 0) {
        return result;
    }
    if (property.collection === "boosters") {
        var boostersObj = JSON.parse(result[0]);
        var strParts = [];
        Object.keys(boostersObj).forEach(function (key) {
            switch (key) {
                case "0": strParts.push("cell: " + boostersObj[key]); break;
                case "1": strParts.push("line: " + boostersObj[key]); break;
                case "2": strParts.push("color: " + boostersObj[key]); break;
                case "3": strParts.push("hint: " + boostersObj[key]); break;
                case "4": strParts.push("wildcard: " + boostersObj[key]); break;
                case "5": strParts.push("shuffle (combo): " + boostersObj[key]); break;
                case "6": strParts.push("moves: " + boostersObj[key]); break;
                case "7": strParts.push("multicolor: " + boostersObj[key]); break;
                case "8": strParts.push("brush: " + boostersObj[key]); break;
                case "9": strParts.push("discover: " + boostersObj[key]); break;
                case "10": strParts.push("magnet: " + boostersObj[key]); break;
                case "11": strParts.push("dice: " + boostersObj[key]); break;
                case "12": strParts.push("jokers: " + boostersObj[key]); break;
                case "13": strParts.push("highlight: " + boostersObj[key]); break;
                case "14": strParts.push("eye: " + boostersObj[key]); break;
                case "15": strParts.push("discover: " + boostersObj[key]); break;
                case "16": strParts.push("shuffle: " + boostersObj[key]); break;
                case "17": strParts.push("tile shuffle: " + boostersObj[key]); break;
                case "18": strParts.push("undo: " + boostersObj[key]); break;
                case "20": strParts.push("replace all: " + boostersObj[key]); break;
                case "21": strParts.push("bomb: " + boostersObj[key]); break;
                case "22": strParts.push("undo: " + boostersObj[key]); break;
                case "23": strParts.push("vacuum: " + boostersObj[key]); break;
            }
        });
        result[0] = strParts.join("\n");
    } else if (property.collection === "tempgoods") {
        var unlimObj = JSON.parse(result[0]);
        if (unlimObj["1"]) {
            unlimObj = unlimObj["1"];
            result[0] = "Buy Time: " + this.formatDate(new Date(unlimObj.buyTime), true) + "\nAction Time: " + unlimObj.actionTime / 60000 + " min";
            if (unlimObj.fpShowed) {
                result[0] += "\nPromotion shown";
            }
        }
    } else if (property.collection === "heroes") {
        var heroesObj = JSON.parse(result[0]);
        strParts = [];
        Object.keys(heroesObj).forEach(function (key) {
            switch (key) {
                case "a": strParts.push("blue: " + heroesObj[key]); break;
                case "b": strParts.push("green: " + heroesObj[key]); break;
                case "c": strParts.push("purple: " + heroesObj[key]); break;
                case "d": strParts.push("red: " + heroesObj[key]); break;
                case "e": strParts.push("yellow: " + heroesObj[key]); break;
                case "w": strParts.push("white: " + heroesObj[key]); break;
            }
        });
        result[0] = strParts.join("\n");
    } else if (property.type === "date") {
        result = formatDate(result, true);
    }
    return result;
};

const isMatch3 = function (game) {
    return ["riddles", "heroes", "runes", "adventure"].includes(game);
};

const isSolitaire = function (game) {
    return ["tripeaks", "spades"].includes(game);
};

const isTile3 = function (game) {
    return ["zenmatch", "tilehero"].includes(game);
};

const isBlocks = function (game) {
    return ["woodenblock", "tetris"].includes(game);
};

const isRUServer = function (source) {
    return ["ok", "vk", "yandex", "mm", "fotostrana"].indexOf(source) !== -1 || window.location.hash.includes("ruserver=true");
};

const hasSimpleMetha = function (game) {
    return ["crocword", "scramble", "magicwords", "tripeaks", "spades", "differences", "runes", "adventure"].includes(game);
};

const hasFarmMetha = function (game) {
    return ["heroes"].includes(game);
};

const hasHomeMetha = function (game) {
    return ["wordsoup"].includes(game);
};

const isMerge = function (game) {
    return ["mergecraft", "wondermerge", "fairy", "hustlemerge"].includes(game);
};

const noLives = function (game) {
    return ["crocword", "wordsoup", "scramble", "magicwords"].includes(game);
};

const noSoft = function (game) {
    return ["runes", "adventure", "crocword", "differences", "heroes", "klondike", "wordsoup", "magicwords", "olympics", "riddles", "spades", "tripeaks"].includes(game);
};

const isEventInElement = function (event, element) {
    var rect = element.getBoundingClientRect();
    var x = event.clientX;
    if (x < rect.left || x >= rect.right) {
        return false;
    }
    var y = event.clientY;
    if (y < rect.top || y >= rect.bottom) {
        return false;
    }
    return true;
};

const getElementCenter = function (element) {
    var rect = element.getBoundingClientRect();
    return {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2
    };
};

const makeUnselectable = function (node, value) {
    if (node.nodeType === 1) {
        if (value) {
            node.classList.add("unselectable");
        } else {
            node.classList.remove("unselectable");
        }
    }
    var child = node.firstChild;
    while (child) {
        makeUnselectable(child, value);
        child = child.nextSibling;
    }
};

const expandTime = function (time, options) {
    var zero = (new Date("2020-01-01")).getTime();
    if (!time || time > zero) {
        return time;
    }
    var precision = options && options.seconds ? 1000 : 60000;

    return time * precision + zero;
};

const listExpeditionsNames = function (configs, game) {
    return ["main"].concat(this.listExpeditions(configs, game).map((f) => f.name));
};

const listExpeditions = function (configs, game) {
    return (configs[game] || {}).expeditions || [];
};

const getRewardUrl = function (gameName, reward) {
    const context = require.context("../assets/rewards", true, /\.png$/);
    const images = context.keys();

    if (reward === "hard" || reward === "gold") {
        reward = this.noSoft(gameName) ? "coin" : "ruby";
    }

    if (reward === "soft" || reward === "coins") {
        reward = "coin";
    }

    const paths = [this.isMerge(gameName) ? `./merge/${reward}.png` : `./${reward}.png`, "./present.png"];
    const image = paths.find((path) => images.includes(path));

    return context(image);
};

const getUnitUrl = function (gameName, code) {
    const context = require.context("../assets/units", true, /\.png$/);
    const images = context.keys();
    const paths = [`./${gameName}/${code}.png`, `./mergecraft/${code}.png`];
    const image = paths.find((path) => images.includes(path));

    return context(image);
};

const getLocationUrl = function (location) {
    const context = require.context("../assets/expeditions", true, /\.png$/);
    const images = context.keys();
    const paths = [`./${location}.png`, "./dragonia.png"];
    const image = paths.find((path) => images.includes(path));

    return context(image);
};

const getOfferUrl = function (offer) {
    const context = require.context("../assets/sidebaricons", true, /\.png$/);
    const images = context.keys();
    const paths = [`./${offer.toLowerCase().replace("_", "")}_offer.png`, "./blackfridaypack_offer.png"];
    const image = paths.find((path) => images.includes(path));

    return context(image);
};

const getMissionUrl = function (mission) {
    const context = require.context("../assets/sidebaricons", true, /\.png$/);
    const images = context.keys();
    const paths = [`./${mission.toLowerCase().replace("_", "")}_mission.png`, "./collectionspass_mission.png"];
    const image = paths.find((path) => images.includes(path));

    return context(image);
};

const getFlagUrl = function (lang) {
    const context = require.context("../assets/flags", false, /\.svg$/);
    if (lang && config.languages.concat(["da", "no"]).indexOf(lang) !== -1) {
        return context("./" + lang + ".svg");
    }
};

const getCountryName = function (code) {
    return iso3311a2.getCountry(code) || "";
};

const getSnapshotLink = function (gameName, userId, source, server) {
    let extraParams = "";
    
    if (server) {
        extraParams += "?server=" + server;
    } else if (this.isStaging()) {
        extraParams = "?stand=true";
    } else if (this.isRUServer(source) || server === "ru") {
        const gameWithOldUrl = ["crocword", "magicwords", "olympics", "riddles", "scramble", "wordsoup"].indexOf(gameName) !== -1;
        extraParams = "?server=" + gameName + (gameWithOldUrl ? "ru.labsystech.ru" : ".labsystech.ru");
    }

    return "https://" + gameName + "-staging.labsystech.ru/" + gameName + "-rest/snapshots/import/" + encodeURIComponent(userId) + extraParams;
};

const isStaging = function () {
    return window.location.hostname === "crm-staging.labsystech.ru";
};

const getFilterFromQuery = function () {
    var searchParams = new URLSearchParams(window.location.hash.split("?")[1]);
    var filter = {
        byTag: [],
        byStatus: [],
        bySource: [],
        byRespondingPerson: [],
        unreadOnlyMode: false,
        withAnswers: false,
        byDate: {},
        byLanguage: [],
        groupGamesAndSources: false,
        payersOnlyMode: false,
        latestVersion: false,
        byEpisode: [],
        byLevel: []
    };

    if (searchParams.get("byTag")) {
        filter.byTag = searchParams.get("byTag").split(",");
    }
    if (searchParams.get("byStatus")) {
        filter.byStatus = searchParams.get("byStatus").split(",");
    }
    if (searchParams.get("bySource")) {
        filter.bySource = searchParams.get("bySource").split(",");
    }
    if (searchParams.get("byRespondingPerson")) {
        filter.byRespondingPerson = searchParams.get("byRespondingPerson").split(",");
    }
    if (searchParams.get("unreadOnlyMode")) {
        filter.unreadOnlyMode = true;
    }
    if (searchParams.get("withAnswers")) {
        filter.withAnswers = true;
    }
    if (searchParams.get("latestVersion")) {
        filter.latestVersion = true;
    }
    if (searchParams.get("byEpisode")) {
        filter.byEpisode = searchParams.get("byEpisode").split(",");
    }
    if (searchParams.get("byLevel")) {
        filter.byLevel = searchParams.get("byLevel").split(",");
    }
    if (searchParams.get("fromDate")) {
        filter.byDate.from = searchParams.get("fromDate");
    }
    if (searchParams.get("toDate")) {
        filter.byDate.to = searchParams.get("toDate");
    }

    return filter;
};

const updateQueryUsingFilter = function (filter) {
    var searchParams = new URLSearchParams();
    if (filter.byTag && filter.byTag.length > 0) {
        searchParams.set("byTag", filter.byTag);
    }
    if (filter.byStatus && filter.byStatus.length > 0) {
        searchParams.set("byStatus", filter.byStatus);
    }
    if (filter.bySource && filter.bySource.length > 0) {
        searchParams.set("bySource", filter.bySource);
    }
    if (filter.byRespondingPerson && filter.byRespondingPerson.length > 0) {
        searchParams.set("byRespondingPerson", filter.byRespondingPerson);
    }
    if (filter.unreadOnlyMode) {
        searchParams.set("unreadOnlyMode", true);
    }
    if (filter.withAnswers) {
        searchParams.set("withAnswers", true);
    }
    if (filter.latestVersion) {
        searchParams.set("latestVersion", true);
    }
    if (filter.byEpisode && filter.byEpisode.length > 0) {
        searchParams.set("byEpisode", filter.byEpisode);
    }
    if (filter.byLevel && filter.byLevel.length > 0) {
        searchParams.set("byLevel", filter.byLevel);
    }
    if (filter.byDate && filter.byDate.from) {
        searchParams.set("fromDate", filter.byDate.from);
    }
    if (filter.byDate && filter.byDate.to) {
        searchParams.set("toDate", filter.byDate.to);
    }

    var params = searchParams.toString();
    if (params.length > 0) {
        params = "?" + params;
    }

    window.location.hash = window.location.hash.split("?")[0] + params;
};

const hasQueryFilter = function () {
    return window.location.hash.split("?")[1] !== undefined;
};

const getUserToolUrl = function (isRuServer, game) {
    return config.userToolUrl
        .replace("{game}", game)
        .replace("{server}", isRuServer ? "ru" : "");
};

export default {
    createDialogueId,
    groupDialogueIdsByGames,
    formatDay,
    formatDate,
    hasPropValues,
    getPropValues,
    getGameInfoPropValues,
    isEventInElement,
    getElementCenter,
    makeUnselectable,
    expandTime,

    isMatch3,
    isSolitaire,
    isTile3,
    isBlocks,
    isRUServer,
    isStaging,
    getUserToolUrl,
    hasSimpleMetha,
    hasFarmMetha,
    hasHomeMetha,
    isMerge,
    noLives,
    noSoft,
    listExpeditionsNames,
    listExpeditions,

    getRewardUrl,
    getUnitUrl,
    getFlagUrl,
    getLocationUrl,
    getOfferUrl,
    getMissionUrl,
    getCountryName,
    getSnapshotLink,

    getFilterFromQuery,
    updateQueryUsingFilter,
    hasQueryFilter
};
