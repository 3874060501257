<template>
    <SidebarMenu :menu="menu" :collapsed="true" :hideToggle="true" :relative="false"/>
    <div class="separator" />
    <div v-if="zenActive" class="zen-current-tag">{{ zenCurrentTag }}</div>
    <circle-progress v-if="zenActive" class="zen-progress" :percent="zenProgress" :size="40" :border-width="8" :border-bg-width="8"/>
    <div v-if="zenActive" class="zen-total-counter">{{ zenTotalCounter }}</div>
</template>
<script>

import SidebarMenu from "@/components/sidebar/sidebarmenu";
import config from "@/config.js";
import CircleProgress from "vue3-circle-progress";

export default {
    name: 'CardMenu',
    components: {
        SidebarMenu,
        CircleProgress
    },
    computed: {
        userCard() {
            return this.$store.state.userCard ? this.$store.state.userCard : {};
        },
        gameName() {
            return this.$route.params.folder.split(':')[1];
        },
        zenActive() {
            return this.$store.state.zen && this.$store.state.zen.active && this.$store.state.zen.userIds.indexOf(this.$route.params.userId) !== -1;
        },
        zenProgress() {
            var data = this.$store.state.zen;
            return 100 - Math.floor(100 * (data.initialUserIdsCount - data.userIds.length) / data.initialUserIdsCount);
        },
        zenTotalCounter() {
            return this.$store.state.zen ? this.$store.state.zen.totalCount : '';
        },
        zenCurrentTag() {
            return this.$store.state.zen && this.$store.state.zen.tags && this.$store.state.zen.tags.length > 0 ? this.$store.state.zen.tags[0] : 'none';
        },
        menu() {
            var items = [{
                title: 'Home',
                icon: 'home',
                noAnimation: true,
                href: { name: 'ZenActivator'}
            }, {
                title: 'Search page',
                icon: 'search_light',
                noAnimation: true,
                action: function () {
                    this.$router.push({name: 'Dialogues', params: {
                            folder: this.$route.params.folder.split(':')[0] + ':' +
                                (this.$store.state.uiState.sidebarGameSelected ? this.$route.params.folder.split(':')[1] : 'all')}});
                }.bind(this)
            }, {
                title: 'Statistic',
                icon: 'statistic',
                noAnimation: true,
                href: 'https://redash.cleverappssg.com/dashboard/crm-statistic',
                external: true
            }, {
                title: 'Versions',
                icon: 'versions',
                noAnimation: true,
                href: { name: 'Versions'}
            }];

            var zenGame = {
                title: 'Zen game',
                icon: this.gameName.split('_')[0].toLowerCase()
            };
            zenGame.child = config.games.map(game => {
                return {
                    title: game,
                    icon: game,
                    count: this.$store.state.count.unread[game],
                    activeChecker: function () {
                        return this.$store.state.zen ? this.$store.state.zen.game : '';
                    }.bind(this),
                    action: function () {
                        this.$store.commit('SET_ZEN_STATE', {
                            active: false,
                            game: game,
                            error: undefined,
                            userIds: [],
                            tags: this.$store.state.zen.tags || []
                        });

                        this.$router.push({name: 'ZenActivator'});
                    }.bind(this)
                }
            });
            items.push(zenGame);

            var zenTag = {
                title: 'Zen tag',
                icon: 'tags_filter'
            };
            var tags = ['none'].concat((this.$store.state.tags.all || []));
            zenTag.child = tags.map(tag => {
                return {
                    title: tag,
                    activeChecker: function () {
                        if (!this.$store.state.zen || !this.$store.state.zen.tags || this.$store.state.zen.tags.length === 0) {
                            return 'none';
                        }
                        return this.$store.state.zen.tags[0];
                    }.bind(this),
                    action: function () {
                        this.$store.commit('SET_ZEN_STATE', {
                            active: false,
                            game: this.gameName,
                            error: undefined,
                            userIds: [],
                            tags: tag === 'none' ? [] : [tag]
                        });

                        this.$router.push({name: 'ZenActivator'});
                    }.bind(this)
                }
            });
            items.push(zenTag);

            return items;
        }
    }
}
</script>

<style scoped>
.zen-progress {
    z-index: 1000;
    position: absolute !important;
    left: 12px;
    top: 250px
}

.zen-total-counter {
    z-index: 10000;
    position: absolute !important;
    left: 12px;
    top: 262px;
    font-size: smaller;
    color: white;
    width: 40px;
    text-align: center;
}

.separator {
    width: 50px;
    z-index: 1000;
    padding: 0 2px;
    border-bottom: 1px solid #CCC;
    position: absolute !important;
    left: 5px;
    top: 200px;
    color: white;
}

.zen-current-tag {
    z-index: 10000;
    position: absolute !important;
    left: 0px;
    top: 226px;
    font-size: smaller;
    color: white;
    width: 64px;
    word-break: keep-all;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>