/**
* Created by Andrey Popov on 12/9/20.
*/

<template>
    <CardMenu ref="cardMenu"/>
    <Gesture ref="gesture"/>

    <div class="after-sidebar" id="gestureTarget" :style="{ opacity: cardOpacity }">
        <perfect-scrollbar class="tabs-container" :style="{'height': profileCollapsed ? '0' : '34%'}">
            <div v-if="currentTab === 'profile'" class="additional-info">
                <PlayerCard :data="userCard" :level="gameLevel" :levelLink="gameLink" class="info-block" />
                <ExperimentCard :tests="abtests" />
                <Column columnTitle="Logs" :columnData="{Server: serverLogsLink, Client: logsLink}" />
            </div>
            <div v-if="currentTab === 'gamestate'">
                <div v-if="snapshotDate" class="p-3 text-secondary">Snapshot date: {{ snapshotDate }}</div>
                <GameLocationProgress v-if="gameLocations" :locations="gameLocations" />
                <MissionsProgress v-if="missions" :missions="missions" />
                <OffersProgress v-if="offers" :offers="offers" />
                <h5 v-if="growthFund" class="mb-2 p-3">Growth Fund: {{ growthFund }}</h5>
                <h5 v-if="noAds" class="mb-2 p-3">No ads enabled</h5>
            </div>
            <div v-if="currentTab === 'snapshot'">
                <iframe class="snapshot-iframe" :src="shapshotLink"></iframe>
            </div>
            <div v-if="currentTab === 'payments'">
                <div class="profile-block">
                    <Properties :properties=mergedPaymentsProperties />
                </div>
            </div>
            <div v-if="currentTab === 'subscriptions'">
                <p class="table-header-title">Subscriptions purchases properties</p>
                <Properties :properties=subscriptionsPurchasesProperties />
                <p class="table-header-title">Subscriptions purchases</p>
                <Properties :properties=subscriptionsProperties />
            </div>
        </perfect-scrollbar>
        <tabs :tabs="tabs" :currentTab="currentTab" @onClick="handleTabClick">
            <template v-slot:extra-items>
                <DropDownButton>
                    <button class="dropdown-button">Gifts</button>
                    <template v-slot:dropdown>
                        <div v-for="giftItem in giftItems" @click="giftItem.action()" class="dropdown-item">
                            {{ giftItem.title }}
                        </div>
                    </template>
                </DropDownButton>
                <DropDownButton>
                    <button class="dropdown-button">Actions</button>
                    <template v-slot:dropdown>
                        <div v-for="action in actionItems" @click="action.action()" class="dropdown-item">
                            <Icon v-if="action.icon" :name="action.icon"/>
                            {{ action.title }}
                        </div>
                    </template>
                </DropDownButton>
                <Icon v-if="!profileCollapsed" class="collapse-icon" title="collapse" name="next"
                      @click="toggleProfile(true)"/>
                <Icon v-if="profileCollapsed" class="expand-icon" title="expand" name="next"
                      @click="toggleProfile(false)"/>
                <DropDown class="tag-selection" :options="availableTags" :hasEditTool="true" :hasDeleteTool="true"
                          v-on:selected="tagSelected" placeholder='Choose tag or enter new'>>
                </DropDown>
                <div v-if="tags" class="tags">
                    <Label class="tag" :colorBg="true" :closeCallback="removeTag" :label="tag"
                           v-for="tag in tags"></Label>
                </div>
            </template>
        </tabs>

        <chat ref="chat"/>
    </div>

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>

</template>

<script>

import CardMenu from "@/components/usercard/cardmenu.vue";
import Gesture from "@/components/usercard/gesture.vue";
import Tabs from '@/controls/tabs.vue';
import Icon from '@/controls/icon.vue';
import Properties from '@/controls/properties.vue';
import Chat from "@/components/chat/chat.vue";
import Label from '@/controls/label.vue';
import DropDown from '@/controls/dropdown';
import DropDownButton from '@/controls/dropdownbutton';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue3-circle-progress/dist/circle-progress.css";

import utils from "@/utils.js";
import config from "@/config.js";
import ExperimentCard from "../components/library/experimentcard.vue";
import PlayerCard from "../components/library/playercard.vue";
import Column from "../components/library/column.vue";
import GameLocationProgress from "../components/library/gameloactionprogress.vue";
import MissionsProgress from "../components/library/missionsprogress.vue";
import OffersProgress from "../components/library/offersprogress.vue";

export default {
    name: 'UserCard',
    components: {
        PlayerCard,
        ExperimentCard,
        Column,
        GameLocationProgress,
        MissionsProgress,
        OffersProgress,
        CardMenu,
        Gesture,
        Chat,
        Tabs,
        Label,
        Icon,
        Properties,
        Loading,
        DropDown,
        DropDownButton
    },
    data() {
        return {
            currentTab: 'profile',
        };
    },
    beforeCreate() {
        if (!this.$store.getters.isAuthenticated) {
            this.$router.push({name: 'Login'});
        }
    },
    created() {
        if (this.$route.params.userId) {
            this.$store.dispatch('fetchUserCard', {
                userId: this.$route.params.userId
            });
        }

        if (!this.$store.state.tags.all) {
            this.$store.dispatch('fetchTags');
        }
        this.$store.dispatch('fetchUnreadCount');

        var game = this.$route.params.folder.split(':')[1];
        if (this.zenActive) {
            this.$store.dispatch('fetchZenTotalCount', game);
        }

        if (!this.$store.state.configs[game]) {
            this.$store.dispatch("fetchConfigs", game);
        }
    },
    computed: {
        isLoading() {
            return this.$store.state.loading;
        },
        userCard() {
            return this.$store.state.userCard ? this.$store.state.userCard : {};
        },
        cardOpacity() {
            return this.$refs.gesture && this.$refs.gesture.opacity;
        },
        userId() {
            return this.$route.params.userId;
        },
        profileCollapsed() {
            return this.$store.state.uiState.userProfileCollapsed;
        },
        zenActive() {
            return this.$store.state.zen && this.$store.state.zen.active && this.$store.state.zen.userIds.indexOf(this.$route.params.userId) !== -1;
        },
        tags() {
            if (!this.userCard.tags) {
                return [];
            }

            return this.userCard.tags.filter(function (tag) {
                return tag.dialogueId == this.userCard.dialogueId;
            }.bind(this)).map(function (tag) {
                return tag.tag;
            });
        },
        availableTags() {
            return this.$store.state.tags.all;
        },
        logsLink() {
            var url = utils.getUserToolUrl(utils.isRUServer(this.userCard.source), this.gameName);
            return url + "/grepclientlogs/" + encodeURIComponent(this.$route.params.userId);
        },
        serverLogsLink() {
            var url = utils.getUserToolUrl(utils.isRUServer(this.userCard.source), this.gameName);
            return url + "/grepserverlogs/" + encodeURIComponent(this.$route.params.userId);
        },
        gameLevel() {
            if (!this.userCard) {
                return '';
            }
            var data = this.userCard;

            var level = "?";
            if (data.level) {
                level = (parseInt(data.episode || 0) * 15 + parseInt(data.level) + 1);
            }

            return "" + level;
        },
        gameLocations() {
            var gameName = this.gameName.split('_')[0].toLowerCase();
            if (utils.isMerge(gameName) && this.userCard.metha) {
                return this.userCard.metha.locations;
            }
        },
        missions() {
            if (!this.userCard.missions) {
                return;
            }

            const configData = this.$store.state.configs[this.gameName] || {};

            return this.userCard.missions.filter(mission => {
                var name = ((configData.missions || []).find(d => d.id === mission.type) || {}).name;
                return name && (!configData.expeditions || !configData.expeditions.includes(name))
            }).map(mission => {
                const data = (configData.missions || []).find(d => d.id === mission.type);
                return data ? { ...mission, name: data.name } : mission;
            });
        },
        offers() {
            if (!this.userCard.offers) {
                return;
            }

            const configData = this.$store.state.configs[this.gameName] || {};
            var missions = this.missions;

            return Object.keys(this.userCard.offers).map(offerType => {
                const data = (configData.offers || []).find(d => d.id == offerType) || {};
                const offer = this.userCard.offers[offerType];
                return {
                    name: data.name,
                    id: offerType,
                    started: offer.started || utils.expandTime(offer.s),
                    finished: offer.finished || utils.expandTime(offer.f),
                    stage: offer.stage || offer.st,
                    lastRewardTime: offer.lastRewardTime || utils.expandTime(offer.l)
                }
            }).filter(offer => {
                var mission = missions.find(m => m.name === offer.name);
                if (!mission && (configData.missions || []).find(m => m.name === offer.name)) {
                    return false;
                }
                return !mission || mission.started || !mission.lastRemoved;
            });
        },
        growthFund() {
            if (!this.userCard.growthfund || this.userCard.growthfund === -1) {
                return;
            }

            if (this.userCard.growthfund === 49) {
                return "finished";
            }
            return "level " + this.userCard.growthfund;
        },
        noAds() {
            return this.userCard.noAds === 1 ? "enabled" : undefined;
        },
        snapshotDate() {
            if (this.userCard.date) {
                return utils.formatDate(this.userCard.date, true);
            }
        },
        abtests() {
            if (!this.userCard || !this.userCard.abtest) {
                return [];
            }

            var configData = (this.$store.state.configs[this.gameName] || {}).ABExperiments || {};

            return Object.keys(this.userCard.abtest).map(function (experimentName) {
                var result = Object.assign({}, configData[experimentName]);
                result.group = this.userCard.abtest[experimentName];
                if (result.group === 0) {
                    result.group = "A";
                }
                if (result.group === 1) {
                    result.group = "B";
                }
                if (result.group === 2) {
                    result.group = "C";
                }
                if (result.group === 3) {
                    result.group = "D";
                }
                result.name = experimentName;
                result.gameName = this.gameName;
                return result;
            }.bind(this));
        },
        gameLink() {
            return utils.getSnapshotLink(this.gameName, this.$route.params.userId, this.userCard.source);
        },
        shapshotLink() {
            return utils.getSnapshotLink(this.gameName, this.$route.params.userId, this.userCard.source);
        },
        tabs() {
            return config.infoTabs;
        },
        gameName() {
            return this.$route.params.folder.split(':')[1];
        },
        subscriptionsPurchasesProperties() {
            return [
                {name: 'ID', collection: 'statSubscriptions', path: 'id'},
                {name: 'Episode', collection: 'statSubscriptions', path: 'episode'},
                {name: 'Level', collection: 'statSubscriptions', path: 'level'},
                {name: 'Item', collection: 'statSubscriptions', path: 'itemId'},
                {name: 'Price', collection: 'statSubscriptions', path: 'textPrice'},
                {name: 'Source', collection: 'statSubscriptions', path: 'source'},
                {name: 'Date', collection: 'statSubscriptions', path: 'event', type: 'date'}]
                .filter((prop) => utils.hasPropValues(this.userCard, prop))
                .map((prop) => {
                    return {
                        header: prop.name,
                        type: prop.type,
                        values: utils.getPropValues(this.userCard, prop)
                    }
                });
        },
        subscriptionsProperties() {
            if (utils.hasPropValues(this.userCard, {collection: 'subscriptions', path: 'data'})) {
                var result = ['ID', 'Source', 'Last reward time', 'Last reload time', 'Trial bought time', 'Active', 'Action']
                    .map((prop) => {
                        return {
                            header: prop,
                            values: []
                        }
                    });

                var subs = this.userCard.subscriptions;
                for (var i = 0; i < subs.length; i++) {
                    if (subs[i].data !== undefined) {
                        var data = JSON.parse(subs[i].data);
                        for (var t in data) {
                            result[0].values.push(data[t].id || "");
                            result[1].values.push(t);
                            result[2].values.push(data[t].lastRewardTime > 0 ? data[t].lastRewardTime : data[t].rewardTime > 0 ? data[t].rewardTime : '');
                            result[2].type = 'date'
                            result[3].values.push(data[t].lastReloadTime > 0 ? data[t].lastReloadTime : '');
                            result[3].type = 'date';
                            result[4].values.push(data[t].trialBoughtTime > 0 ? data[t].trialBoughtTime : '');
                            result[4].type = 'date';
                            result[5].values.push(data[t].active ? "Yes" : "No");
                            if (this.userCard.source === "android" && data[t].id) {
                                result[6].values.push({
                                    actions: [
                                        {
                                            caption: 'Cancel',
                                            action: this.refund.bind(this, 'subscription', data[t], 1, 'androidSubscriptions')
                                        },
                                        {
                                            caption: 'Refund',
                                            action: this.refund.bind(this, 'subscription', data[t], undefined, 'androidSubscriptions')
                                        }]
                                });
                            } else {
                                result[6].values.push('');
                            }
                        }
                    }
                }
                return result;
            }

            return [];
        },
        mergedPaymentsProperties() {
            return [
                {name: 'Status', collection: 'mergedPayments', path: 'state'},
                {name: 'ProductId', collection: 'mergedPayments', path: 'productId'},
                {name: 'PaymentId', collection: 'mergedPayments', path: 'paymentId'},
                {name: 'Price', collection: 'mergedPayments', path: 'price'},
                {name: 'Local price', collection: 'mergedPayments', path: 'textPrice'},
                {name: 'Date', collection: 'mergedPayments', path: 'event', type: 'date'},
                {name: 'Interval', collection: 'mergedPayments', path: 'interval'},
                {name: 'Action', collection: 'mergedPayments', isAction: true}]
                .filter((prop) => utils.hasPropValues(this.userCard, prop))
                .map((prop) => {
                    var propValues;
                    var data = this.userCard[prop.collection] || [];

                    if (prop.isAction) {
                      propValues = data.map((payment) => {
                        if (!payment.refundId) {
                          return '';
                        }

                        var actions = [];
                        if (['subsweek', 'subsmonth'].indexOf(payment.productId) !== -1) {
                          actions.push({
                            caption: 'Cancel',
                            action: this.refund.bind(this, 'subscription', payment, 1)
                          });
                        }
                        actions.push({caption: 'Refund', action: this.refund.bind(this, 'payment', payment)});
                        return {actions: actions};
                      });
                    } else if (prop.name === 'Status') {
                        propValues = data.map((payment) => {
                            return payment.state ? {icon: `payment_${payment.state}`, iconTitle: payment.stateTitle } : "";
                        });
                    } else if (prop.name === 'Local price') {
                        propValues = data.map((payment) => {
                            if (!payment.textPrice) {
                                return "";
                            }
                            if (payment.textPrice.indexOf('**') !== -1) {
                                return {
                                    icon: "price_ok",
                                    iconValue: payment.textPrice.replace("**", "")
                                }
                            } else if (payment.textPrice.indexOf('&&') !== -1) {
                                return {
                                    icon: "price_yandex",
                                    iconValue: payment.textPrice.replace("&&", "")
                                }
                            } else if (payment.textPrice.indexOf('{{') !== -1) {
                                return {
                                    icon: "price_kred",
                                    iconValue: payment.textPrice.replace("{{", "")
                                }
                            }
                            return payment.textPrice;
                        });
                    } else {
                      propValues = utils.getPropValues(this.userCard, prop);
                    }

                    return {
                        header: prop.name,
                        type: prop.type,
                        values: propValues
                    }
                })
                .filter((prop) => prop.header !== 'Action' || prop.values.find(v => v));
        },
        messageManager() {
            return this.$refs.chat.$refs.messageManager;
        },
        giftItems: function () {
            var presents = [{
                title: 'Make present',
                action: this.messageManager.showPresentModal.bind(this)
            }];

            var gameName = this.gameName.split('_')[0].toLowerCase()
            if (utils.isMerge(gameName)) {
                presents.push({
                    title: 'Add unit',
                    action: this.messageManager.showAddUnitModal.bind(this)
                });

                presents.push({
                    title: 'Add harvested',
                    action: this.messageManager.showHarvestedModal.bind(this)
                });
            } else {
                presents.push({
                    title: 'Set episode & level',
                    action: this.messageManager.showProgressModal.bind(this)
                });
            }
            if (utils.isMatch3(gameName)) {
                presents.push({
                    title: 'Upgrade heroes',
                    action: this.messageManager.showHeroesModal.bind(this)
                });
            }
            if (utils.hasSimpleMetha(gameName) || utils.hasFarmMetha(gameName) || utils.hasHomeMetha(gameName)) {
                presents.push({
                    title: 'Set metha',
                    action: this.messageManager.showMethaProgressModal.bind(this)
                });
            }

            presents.push({
              title: 'Copy progress',
              action: this.messageManager.showRestoreProgressModal.bind(this)
            });

            presents.push({
              title: 'Migrate progress',
              action: this.messageManager.showMigrateProgressModal.bind(this)
            });

            return presents;
        },
        actionItems: function () {
            var game = this.$route.params.folder.split(':')[1];
            var dialogueIds = [utils.createDialogueId(game, this.$store.state.dialogueId)];
            var dialogueMessages = (this.userCard.messages || []).filter((message) => {
                return message.dialogueId == this.$store.state.dialogueId;
            });
            var actions = [];
            if (dialogueMessages.find(message => message.starred === 1)) {
                actions.push({
                    title: 'Remove star',
                    icon: 'star-on',
                    action: function () {
                        this.$store.dispatch('unmark', {dialogueIds: dialogueIds, userId: this.$route.params.userId});
                        this.$router.push({
                            name: 'UserCard',
                            params: {folder: "Inbox:" + game, userId: this.$route.params.userId}
                        });
                    }.bind(this)
                });
            }

            if (dialogueMessages.find(message => message.starred !== 1)) {
                actions.push({
                    title: 'Add star',
                    icon: 'star-off',
                    action: function () {
                        this.$store.dispatch('mark', {dialogueIds: dialogueIds, userId: this.$route.params.userId});
                        this.$router.push({
                            name: 'UserCard',
                            params: {folder: "Starred:" + game, userId: this.$route.params.userId}
                        });
                    }.bind(this)
                });
            }

            if (!this.zenActive) {
                if (dialogueMessages.find(message => message.isResponse === 0 && message.readByUser === 1)) {
                    actions.push({
                        title: 'Mark as unread',
                        icon: 'mark-unread',
                        action: function () {
                            this.$store.dispatch('markAsUnread', {
                                dialogueIds: dialogueIds,
                                userId: this.$route.params.userId
                            });
                        }.bind(this)
                    });
                }
                if (dialogueMessages.find(message => message.isResponse === 0 && message.readByUser === 0)) {
                    actions.push({
                        title: 'Mark as read',
                        icon: 'mark-read',
                        action: function () {
                            this.$store.dispatch('markAsRead', {
                                dialogueIds: dialogueIds,
                                userId: this.$route.params.userId
                            });
                        }.bind(this)
                    });
                }
            }

            actions = actions.concat([{
                title: 'Remove user',
                icon: 'spam',
                action: function () {
                    if (confirm("Are you sure that you want to delete user " + this.$route.params.userId + "?")) {
                        this.$store.dispatch('removeUser', this.$route.params.userId, utils.isRUServer(this.userCard.source));
                    }
                }.bind(this)
            }, {
                title: 'Set cheater',
                action: function () {
                    if (confirm("Are you sure that you want mark user " + this.$route.params.userId + " as cheater? (Remove from daily cup)")) {
                        this.$store.dispatch('setCheater', this.$route.params.userId, utils.isRUServer(this.userCard.source));
                    }
                }.bind(this)
            }]);

            if (this.$route.params.folder.split(':')[0] === 'Spam') {
                actions.push({
                    title: 'Not spammer',
                    action: function () {
                        this.$store.dispatch('setSpammer', {id: this.$route.params.userId, value: 0});
                    }.bind(this)
                });
            } else {
                actions.push({
                    title: 'Set spammer',
                    action: function () {
                        if (confirm("Are you sure that you want mark user " + this.$route.params.userId + " as spammer?")) {
                            this.$store.dispatch('setSpammer', {id: this.$route.params.userId, value: 1});
                        }
                    }.bind(this)
                });
            }

            actions.push({
                title: 'Toggle console stream',
                action: function () {
                    if (this.messageManager.consoleStreamEnabled) {
                        this.messageManager.dispatchMessage("Disable console stream", 'stream_off');
                    } else {
                        this.messageManager.dispatchMessage("Enable console stream", 'stream_on');
                    }
                }.bind(this)
            });

            return actions;
        }
    },
    methods: {
        handleTabClick(newTab) {
            this.toggleProfile(false);
            this.currentTab = newTab;
        },
        refund(name, payment, cancel, infoTable) {
            if (!confirm("Are you sure that you want to " + (cancel === 1 ? "cancel " : "refund ") + name + " for user " + this.userId + "?")) {
                return;
            }

            this.$store.dispatch('androidRefund', {
                userid: this.userId,
                payment: payment,
                infoTable: infoTable,
                cancel: cancel
            }, utils.isRUServer(this.userCard.source));

        },
        toggleProfile(state) {
            this.$store.commit('SET_UI_STATE', {
                sidebarCollapsed: this.$store.state.uiState.sidebarCollapsed,
                sidebarGameSelected: this.$store.state.uiState.sidebarGameSelected,
                userProfileCollapsed: state
            });

            setTimeout(function () {
                this.$refs.chat.adjustChatHeight();
            }.bind(this), 300);
        },
        tagSelected(option) {
            if (typeof option === 'string' && option.length > 0) {
                var action = 'addTag';
                var params = {
                    tag: option.substring(0, 20),
                    author: this.$store.state.respondingPerson,
                    userid: this.$route.params.userId,
                    dialogueid: this.$store.state.dialogueId,
                    game: this.$route.params.folder.split(':')[1]
                }

                this.$store.dispatch(action, params);
            }
        },
        removeTag(label) {
            this.$store.dispatch('removeTag', {
                userid: this.$route.params.userId,
                dialogueid: this.$store.state.dialogueId,
                tag: label,
                game: this.$route.params.folder.split(':')[1],
                fromUserCard: true
            });
        }
    },
    watch: {
        $route() {
            if (this.$route.params.userId) {
                this.$store.dispatch('fetchUserCard', {
                    userId: this.$route.params.userId
                });
            }
        }
    }
}
</script>

<style scoped>

.after-sidebar {
    margin-left: 70px;
    height: 100%;
}

.tabs-container {
    overflow-y: auto;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px 8px 0 0;
    width: 98%;
    margin-left: 10px;
    margin-top: 4px;
    -webkit-transition: height .3s ease;
    transition: height .3s ease
}

.additional-info {
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
}

.table-header-title {
    text-align: center;
    font-size: large;
}

.info-block {
    display: inline-block;
}

.profile-block {
    margin-left: 30px;
    display: inline-block;
}

.collapse-icon {
    float: right;
    margin: 12px 20px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.expand-icon {
    float: right;
    margin: 12px 20px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.action-button {
    display: inline-block;
    border-radius: 6px;
    border: none;
    height: 24px;
    width: 140px
}

a.action-button {
    margin-top: 14px;
    color: #5d91d0;
    cursor: pointer;
    font-family: Arial;
    text-align: center;
    text-decoration: none;
}

a.redButton {
    background-color: #004A7F;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Arial;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    -webkit-animation: glowing 1000ms infinite;
    -moz-animation: glowing 1000ms infinite;
    -o-animation: glowing 1000ms infinite;
    animation: glowing 1000ms infinite;
}

.dropdown-button {
    font-size: 18px;
    margin: 5px 2px;
    letter-spacing: 0.8px;
    color: rgb(45 144 220);
    font-weight: 600;
    padding: .5rem 1rem;
    background-color: #ffffff;
    border-radius: .25rem;
    border: none;

    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.dropdown-button:hover {
    background-color: rgb(212 212 212);
    color: rgb(36 115 176);
    font-weight: 600;
}

.dropdown-button::after {
    display: inline-block;
    margin-left: .3em;
    vertical-align: .155em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 4px 12px;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin: 0px 4px;
}

.dropdown-item:hover {
    font-weight: 900;
}

.unselectable {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tags {
    margin: 11px;
    color: #5f5f5f;
    display: inline-block;
    float: right;
}

.tag {
    display: inline-block;
}

.tag-selection {
    position: relative;
    float: right;
    margin: 6px;
}

.snapshot-iframe {
    margin: 20px;
    height: 28vh;
    width: 46vw;
    border: none;
}

</style>
