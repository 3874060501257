<template>
    <div class="card shadow-sm column-card">
        <div class="card-header bg-light fw-bold d-flex align-items-center">
            <span class="me-2">📝</span> {{ columnTitle }}
        </div>
        <div class="card-body p-3">
            <ul class="list-group list-group-flush">
                <li v-for="(value, key) in columnData" :key="key" class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="text-truncate flex-grow-1 me-3 fw-bold">{{ formatKey(key) }}</div>
                    <a :href="value" target="_blank" class="btn btn-sm btn-outline-primary flex-shrink-0">Check</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "Column",
    props: {
        columnTitle: { type: String },
        columnData: { type: Object, required: true }
    },
    methods: {
        formatKey(key) {
            return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
        }
    }
};
</script>

<style scoped>
.column-card {
    max-width: 350px;
    margin: 0 0 10px 10px;
    height: 180px;
}
.card-header {
    font-size: 1rem;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}
.list-group-item {
    padding: 12px 15px;
    font-size: 0.9rem;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    align-items: center;
    gap: 10px;
}
.list-group-item:last-child {
    border-bottom: none;
}
.btn-sm {
    font-size: 0.85rem;
}
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>