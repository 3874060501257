<template>
    <SidebarMenu :menu="menu" :collapsed="true" :hideToggle="true" :relative="false" />
    <perfect-scrollbar class="content">
        <div class="caption">Versions</div>
        <div>
            <table class="versions-table">
                <thead>
                <tr>
                    <th rowspan="2"></th>
                    <template v-for="(group, groupIndex) in platformGroups" :key="group.priority">
                        <th :colspan="group.items.length + (groupIndex < platformGroups.length - 1 ? 1 : 0)" class="platform-group-header">
                            {{ group.title }}
                        </th>
                    </template>
                </tr>
                <tr>
                    <template v-for="(group, groupIndex) in platformGroups" :key="group.priority">
                        <th v-for="platform in group.items" :key="platform">
                            <Icon class="platform-icon" :title="platform" :name="platform" />
                        </th>
                        <th v-if="groupIndex < platformGroups.length - 1" :class="getPlatformClassSeparator(group.priority)"></th>
                    </template>
                </tr>
                </thead>
                <tbody>
                <template v-for="(gameGroup, i) in gameGroups" :key="gameGroup.priority">
                    <tr class="game-group-header" :class="'game-group-header' + i">
                        <td :colspan="1 + platformColumnsCount">{{ gameGroup.title }}</td>
                    </tr>
                    <tr v-for="game in gameGroup.items" :key="game">
                        <td>
                            <Icon class="game-icon" :title="game" :name="game" />
                        </td>
                        <template v-for="(group, groupIndex) in platformGroups" :key="group.priority">
                            <td v-for="platform in group.items" :key="platform">
                                <div :class="getGameVersionStyle(game, platform)">
                                    {{ getGameVersion(game, platform) }}
                                </div>
                            </td>
                            <td v-if="groupIndex < platformGroups.length - 1" :class="getPlatformClassSeparator(group.priority)"></td>
                        </template>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
    </perfect-scrollbar>
</template>

<script>
import SidebarMenu from "@/components/sidebar/sidebarmenu";
import PropertiesTable from "@/components/library/propertiestable";
import Icon from "../controls/icon.vue";
import config from "@/config.js";

export default {
    name: "Versions",
    components: {
        Icon,
        SidebarMenu,
        PropertiesTable
    },
    beforeCreate() {
        if (!this.$store.getters.isAuthenticated) {
            this.$router.push({ name: 'Login' });
        }
    },
    created() {
        this.$store.dispatch('fetchVersions');
    },
    computed: {
        menu() {
            return [{
                title: 'Home',
                icon: 'home',
                noAnimation: true,
                href: { name: 'ZenActivator' }
            }, {
                title: 'Search page',
                icon: 'search_light',
                noAnimation: true,
                action: function () {
                    this.$router.push({ name: 'Dialogues', params: { folder: "Inbox:all" } });
                }.bind(this)
            }];
        },
        platformGroups() {
            const p = config.priorities;
            return [
                { priority: 'high', title: 'High Priority', items: p.high.sources },
                { priority: 'medium', title: 'Medium Priority', items: p.medium.sources },
                { priority: 'low', title: 'Low Priority', items: p.low.sources }
            ];
        },
        allPlatforms() {
            return this.platformGroups.reduce((acc, group) => acc.concat(group.items), []);
        },
        platformColumnsCount() {
            return this.platformGroups.reduce((acc, group, index) => {
                return acc + group.items.length + (index < this.platformGroups.length - 1 ? 1 : 0);
            }, 0);
        },
        gameGroups() {
            const p = config.priorities;
            return [
                { priority: 'high', title: 'High Priority', items: p.high.games },
                { priority: 'medium', title: 'Medium Priority', items: p.medium.games },
                { priority: 'low', title: 'Low Priority', items: p.low.games }
            ];
        }
    },
    methods: {
        getGameVersion(game, platform) {
            if (!this.$store.state.versions) {
                return "";
            }
            return (this.$store.state.versions[game] && this.$store.state.versions[game][platform]) || "-";
        },
        getGameVersionStyle(game, platform) {
            if (!this.$store.state.versions || !this.$store.state.versions[game] || !this.$store.state.versions[game][platform]) {
                return "game-version";
            }
            const latestVersion = Object.values(this.$store.state.versions[game]).sort(function (a, b) {
                if (!a || !b) return !!a - !!b;
                var arrayA = a.split(".");
                var arrayB = b.split(".");
                for (var i = 0; i < Math.min(arrayA.length, arrayB.length); i++) {
                    var v1 = parseInt(arrayA[i]);
                    var v2 = parseInt(arrayB[i]);
                    if (v1 !== v2) return v1 - v2;
                }
                return arrayA.length - arrayB.length;
            }).at(-1);
            return this.$store.state.versions[game][platform] === latestVersion ? "game-version-normal" : "game-version-old";
        },
        getPlatformClassSeparator(priority) {
            if (priority === 'high') return 'version-bg-high';
            if (priority === 'medium') return 'version-bg-medium';
            if (priority === 'low') return 'version-bg-low';
            return '';
        }
    }
};
</script>

<style scoped>
.caption {
    font-size: 24px;
    font-weight: 700;
    height: auto;
    line-height: 32px;
    margin: 20px 20px 20px 80px;
}
.versions-table {
    width: 90%;
    border-collapse: collapse;
    font-size: 15px;
    margin: 20px 10px 20px 80px;
    padding: 20px;
    overflow-x: scroll;
}
.versions-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}
.versions-table th,
.versions-table td {
    padding: 5px;
    text-align: center;
    border: 1px solid #ddd;
    font-size: 15px;
    white-space: nowrap;
}
.versions-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    text-align: center;
}
.game-icon {
    width: 40px;
    height: 40px;
    margin: 0 6px;
    color: #007bff;
    transition: color 0.3s;
}
.game-icon:hover {
    color: #0056b3;
}
.game-version {
    color: black;
}
.game-version-normal {
    color: #2a763b;
}
.game-version-old {
    color: #dc3545;
}
.game-group-header td {
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
}
.game-group-header0 td {
    background-color: #dc3545;
}
.game-group-header1 td {
    background-color: #fd7e14;
}
.game-group-header2 td {
    background-color: #ffc107;
}
.platform-group-header {
    background-color: #e9ecef;
    font-weight: bold;
}
.version-bg-high {
    background-color: #f8d7da;
}
.version-bg-medium {
    background-color: #fff3cd;
}
.version-bg-low {
    background-color: #d1ecf1;
}
.game-name {
    font-weight: bold;
}
@media screen and (max-width: 768px) {
    .versions-table th,
    .versions-table td {
        padding: 10px;
        font-size: 12px;
    }
    .game-icon {
        font-size: 18px;
    }
}
</style>
