<template>
    <div v-if="isAuthenticated" class="sub-header">
        <div class="paging" v-if="singleGameSelected && dialogues.length > 0 ">
            <strong>{{ pageFrom }}-{{ pageTo }}</strong>
            <strong v-if="!singleGameSearchInProgress"> of {{ pageCount }}</strong>
            <a class="btn" title="Previous">
                <Icon name="prev" @click="prevPage" v-if="pageFrom !== 1"/>
            </a>
            <a class="btn" title="Next">
                <Icon name="next" @click="nextPage" v-if="pageTo !== pageCount"/>
            </a>
        </div>
        <CheckBox class="checkbox-all" :onChange="bulkToggle"
                  :selected="allSelected" :someSelected="someSelected"/>
        <div class="icons-container">
            <a class="btn" title="Add star" v-if="someStarOffSelected" @click="addStar">
                <Icon name="star-off"/>
            </a>
            <a class="btn" title="Remove star" v-if="someStarOnSelected" @click="removeStar">
                <Icon name="star-on"/>
            </a>
            <a class="btn" title="Mark as unread" v-if="someSelected && someReadByUser" @click="markAsUnread">
                <Icon name="mark-unread"/>
            </a>
            <a class="btn" title="Mark as read" v-if="someSelected && someUnreadByUser" @click="markAsRead">
                <Icon name="mark-read"/>
            </a>
            <a v-if="!isInbox" class="btn" @click="refresh">
                <Icon name="refresh"/>
            </a>
        </div>
        <DropDown :placeholder="'filter by tag'" :options="availableTags" v-on:selected="tagFilterChanged"></DropDown>
        <div class="top-filter">
            <Label class="top-filter-label" :closeCallback="filterLabelClosed" :label="tag" :filterName="'filterByTag'" :colorBg="true" v-for="tag in filterByTag.slice(0, 4)"></Label>
        </div>
        <DropDown :placeholder="'filter by source'" :options="availableSources" v-on:selected="sourceSelected"></DropDown>
        <div class="top-filter">
            <Label class="top-filter-label" :closeCallback="filterLabelClosed" :label="source" :filterName="'filterBySource'" v-for="source in filterBySource.slice(0, 4)"></Label>
        </div>
        <button :title="filterDescription" class="actionButton" style="float: none" @click="showFilterModal">More filters</button>
        <div v-if="filterDescription.length > 0" class="filter-desc" :title="filterDescription"></div>
        <button title="Write to user by id" class="actionButton" style="float: none" @click="showWriteToUserModal">Find user</button>
        <button title="Run search by filters" class="actionButton" style="float: none" @click="applyFilter">Run search</button>
        <Icon v-if="singleGameSelected" :title="gameName" :name="gameName.split('_')[0].toLowerCase()" />
    </div>
    <modal v-if="showFilter" @close="showFilter = false" :sendCallback="applyFilter" :cancelCallback="cancelFilter" :isFilter=true>
        <template v-slot:header>
            <h2>More filters</h2>
        </template>
        <template v-slot:body>
            <div class="date-filter-block">
                <div class="date-checkbox">
                    <CheckBox :onChange="toggleDateFilter" :selected="hasDateFilter"/>By date
                </div>
                <div v-if="hasDateFilter">From:</div>
                <datepicker v-if="hasDateFilter" v-model="fromDate" />
                <div v-if="hasDateFilter">To:</div>
                <datepicker v-if="hasDateFilter" v-model="toDate" />
            </div>
            <div class="filter-block">
                <div class="filter-block-caption">By status</div>
                <DropDown :options="availableStatuses" v-on:selected="statusSelected"></DropDown>
                <div class="filter-tags">
                    <Label :label="status" :closeCallback="filterLabelClosed" :filterName="'filterByStatus'" v-for="status in filterByStatus"></Label>
                </div>
            </div>
            <div class="filter-block">
                <div class="filter-block-caption">By person</div>
                <DropDown :options="availableRespondingPersons" v-on:selected="respondingPersonSelected"></DropDown>
                <div class="filter-tags">
                    <Label :label="person" :closeCallback="filterLabelClosed" :filterName="'filterByRespondingPerson'" v-for="person in filterByRespondingPerson"></Label>
                </div>
            </div>
            <div class="filter-block">
                <div class="filter-block-caption">By language</div>
                <DropDown :options="availableLanguages" v-on:selected="languageSelected"></DropDown>
                <div class="filter-tags">
                    <Label :label="language" :closeCallback="filterLabelClosed" :filterName="'filterByLanguage'" v-for="language in filterByLanguage"></Label>
                </div>
            </div>
            <div v-if="!isMerge && singleGameSelected" class="filter-block">
                <div class="filter-block-caption">By episode</div>
                <DropDown :options="availableEpisodes" v-on:selected="episodeSelected"></DropDown>
                <div class="filter-tags">
                    <Label :label="episode" :closeCallback="filterLabelClosed" :filterName="'filterByEpisode'" v-for="episode in filterByEpisode"></Label>
                </div>
            </div>
            <div v-if="isMerge && singleGameSelected && false" class="filter-block">
                <div class="filter-block-caption">By level</div>
                <DropDown :options="availableLevels" v-on:selected="levelSelected"></DropDown>
                <div class="filter-tags">
                    <Label :label="level" :closeCallback="filterLabelClosed" :filterName="'filterByLevel'" v-for="level in filterByLevel"></Label>
                </div>
            </div>
            <div class="checkbox-filters filter-block">
                <div class="filter-block-caption">With answers</div>
                <Switch :onChange="withAnswersChanged" :selected="withAnswersShown"/>
            </div>
            <div class="checkbox-filters filter-block">
                <div class="filter-block-caption">Latest version</div>
                <Switch :onChange="latestVersionChanged" :selected="latestVersionShown"/>
            </div>
        </template>
    </modal>
    <modal v-if="showWriteToUser" :sendCallback="redirectToUser" :cancelCallback="closeRedirectForm" :isFilter=true>
        <template v-slot:header>
            <h2>Find user by ID or name</h2>
        </template>
        <template v-slot:body>
            <DropDown title="Game" class="container" :showSelected="true" :options="gamesList" v-on:selected="writeToUserGameChanged" placeholder='Game'>></DropDown>
            <div v-if="!isStaging" class="container">RU</div>
            <Switch v-if="!isStaging" :onChange="ruUserChanged" :selected="isRUUser"/>
            <StringInput title="User ID or name" v-model="writeToUserId"/>
            <h4>Search results:</h4>
            <perfect-scrollbar class="container-for-users">
                <a :href="searchUserLink(userId)" target="_blank" v-for="userId in usersByName">{{userId}}</a>
            </perfect-scrollbar>
        </template>
    </modal>
</template>

<script>

import Icon from '@/controls/icon.vue'
import CheckBox from '@/controls/checkbox.vue'
import router from "../router";
import DropDown from '@/controls/dropdown';
import Label from '@/controls/label.vue';
import Switch from '@/controls/switch.vue';
import StringInput from '@/controls/stringinput.vue';
import config from "@/config.js";
import Modal from '@/controls/modal.vue';
import Datepicker from 'vue3-datepicker'
import moment from "moment";
import axios from "axios";
import utils from "@/utils";

export default {
    name: 'subheader',
    components: {
        Icon,
        CheckBox,
        DropDown,
        Label,
        Switch,
        StringInput,
        Modal,
        Datepicker
    },
    data() {
        return {
            showFilter: false,
            hasDateFilter: false,
            showWriteToUser: false,
            fromDate: moment().startOf('isoweek').toDate(),
            toDate: moment().endOf('isoweek').toDate(),
            writeToUserGame: undefined,
            writeToUserId: undefined,
            isRUUser: false,
            usersByName: []
        }
    },
    computed: {
        isAuthenticated() {
            return this.$store.state.token;
        },
        dialogues() {
            return this.$store.state.dialogues;
        },
        selectedDialogueIds() {
            return this.$store.state.selectedDialogueIds;
        },
        pageFrom() {
            return this.$store.state.page.selected * this.$store.state.page.size + 1;
        },
        pageTo() {
            return this.$store.state.page.selected * this.$store.state.page.size + Math.min(this.$store.state.page.size, this.dialogues.length);
        },
        pageCount() {
            if (this.singleGameSearchInProgress) {
                return this.dialogues.length === this.$store.state.page.size ? this.pageTo + 1 : this.pageTo;
            } else {
                var folder = router.currentRoute.value.params.folder.split(':')[0];
                var inboxCount = folder.startsWith('$') ? this.$store.state.count.inboxPayers : this.$store.state.count.inbox;

                if (inboxCount === 0 && this.dialogues.length > 0 && this.singleGameSelected) {
                    this.$store.dispatch('fetchInboxCount', this.$route.params.folder.split(':')[1]);
                }
                return inboxCount;
            }
        },
        singleGameSearchInProgress() {
            return this.$store.state.query.length >= 3 && this.singleGameSelected;
        },

        someSelected() {
            return this.selectedDialogueIds.length > 0
        },
        someStarOnSelected() {
            return this.dialogues.filter((dialogue) => {
                return dialogue.starred === 1 && this.selectedDialogueIds.indexOf(dialogue.id) !== -1;
            }).length > 0;
        },
        someStarOffSelected() {
            return this.dialogues.filter((dialogue) => {
                return dialogue.starred !== 1 && this.selectedDialogueIds.indexOf(dialogue.id) !== -1;
            }).length > 0;
        },
        noneSelected() {
            return this.selectedDialogueIds.length == 0
        },
        allSelected() {
            return this.selectedDialogueIds.length > 0 && this.selectedDialogueIds.length == this.dialogues.length
        },

        someReadByUser() {
            return this.dialogues.filter((dialogue) => {
                return dialogue.isResponse === 0 && dialogue.readByUser === 1 && this.selectedDialogueIds.indexOf(dialogue.id) !== -1;
            }).length > 0;
        },
        someUnreadByUser() {
            return this.dialogues.filter((dialogue) => {
                return dialogue.isResponse === 0 && dialogue.readByUser === 0 && this.selectedDialogueIds.indexOf(dialogue.id) !== -1;
            }).length > 0;
        },
        isInbox() {
            if (!this.$route.params.folder) {
                return false;
            }
            return this.$route.params.folder.split(':')[0].indexOf('Inbox') !== -1;
        },
        singleGameSelected() {
            if (!this.$route.params.folder) {
                return false;
            }
            var games = this.$route.params.folder.split(':')[1].split(',');
            return games.length === 1 && games[0] !== 'all';
        },
        userCard() {
            return this.$store.state.userCard ? this.$store.state.userCard : {};
        },
        availableTags() {
            return (this.$store.state.tags.all || []).filter(function (tag) {
                return this.filterByTag.indexOf(tag) === -1;
            }.bind(this));
        },
        availableStatuses() {
            return ['new', 'renewal', 'resolved', 'no answer needed', 'pending'].filter(function (status) {
                return this.filterByStatus.indexOf(status) === -1;
            }.bind(this));
        },
        availableSources() {
            return config.sources.filter(function (source) {
                return this.filterBySource.indexOf(source) === -1;
            }.bind(this));
        },
        availableRespondingPersons() {
            return config.respondingPersons.filter(function (person) {
                return this.filterByRespondingPerson.indexOf(person) === -1;
            }.bind(this));
        },
        availableLanguages() {
            return config.languages.filter(function (language) {
                return this.filterByLanguage.indexOf(language) === -1;
            }.bind(this));
        },
        availableLevels() {
            var levels = [];
            for (var i = 0; i <= 100; i++) {
                if (this.filterByLevel.indexOf(i+'') === -1) {
                    levels.push(i+'');
                }
            }
            return levels;
        },
        availableEpisodes() {
            var episodes = [];
            for (var i = 0; i <= 300; i++) {
                if (this.filterByEpisode.indexOf(i+'') === -1) {
                    episodes.push(i+'');
                }
            }
            return episodes;
        },
        gameName() {
            return this.$route.params.folder.split(':')[1];
        },
        tagFilterMode() {
            return this.noneSelected;
        },
        filterByTag() {
            return this.$store.state.filter.byTag;
        },
        filterByStatus() {
            return this.$store.state.filter.byStatus;
        },
        filterBySource() {
            return this.$store.state.filter.bySource;
        },
        filterByRespondingPerson() {
            return this.$store.state.filter.byRespondingPerson || [];
        },
        filterByLanguage() {
            return this.$store.state.filter.byLanguage || [];
        },
        filterByLevel() {
            return this.$store.state.filter.byLevel || [];
        },
        filterByEpisode() {
            return this.$store.state.filter.byEpisode || [];
        },
        withAnswersShown() {
            return this.$store.state.filter.withAnswers;
        },
        latestVersionShown() {
            return this.$store.state.filter.latestVersion;
        },
        shouldGroupGamesAndSources() {
            return this.$store.state.filter.groupGamesAndSources;
        },
        payersOnlyMode() {
            return this.$store.state.filter.payersOnlyMode;
        },
        isMerge() {
            return ['mergecraft', 'wondermerge', "fairy"].includes(this.$route.params.folder.split(':')[1].split('_')[0].toLowerCase());
        },
        filterDescription() {
            var filterParts = [];
            if (this.$store.state.filter.withAnswers) {
                filterParts.push('with answers');
            }
            if (this.$store.state.filter.latestVersion) {
                filterParts.push('latest version');
            }
            if (this.$store.state.filter.byStatus && this.$store.state.filter.byStatus.length > 0) {
                filterParts.push('statuses: ' + this.$store.state.filter.byStatus.join(' '));
            }
            if (this.$store.state.filter.byRespondingPerson && this.$store.state.filter.byRespondingPerson.length > 0) {
                filterParts.push('persons: ' + this.$store.state.filter.byRespondingPerson.join(' '));
            }
            if (this.$store.state.filter.byLanguage && this.$store.state.filter.byLanguage.length > 0) {
                filterParts.push('lang: ' + this.$store.state.filter.byLanguage.join(' '));
            }
            if (this.$store.state.filter.byDate && this.$store.state.filter.byDate.from && this.$store.state.filter.byDate.to) {
                filterParts.push('from ' + this.$store.state.filter.byDate.from.split('T')[0] + ' to ' + this.$store.state.filter.byDate.to.split('T')[0]);
            }
            if (this.$store.state.filter.byLevel && this.$store.state.filter.byLevel.length > 0) {
                filterParts.push('level: ' + this.$store.state.filter.byLevel.join(' '));
            }
            if (this.$store.state.filter.byEpisode && this.$store.state.filter.byEpisode.length > 0) {
                filterParts.push('episode: ' + this.$store.state.filter.byEpisode.join(' '));
            }

            return filterParts.join(", ");
        },
        gamesList() {
            return config.games;
        },
        isStaging() {
            return utils.isStaging();
        }
    },
    methods: {
        selectAll() {
            this.$store.commit('DIALOGUES_SELECT_ALL');
        },
        selectNone() {
            this.$store.commit('DIALOGUES_SELECT_NONE');
        },
        bulkToggle() {
            if (this.noneSelected) {
                this.selectAll()
            } else {
                this.selectNone()
            }
        },
        back() {
            this.$router.push({name: 'ZenActivator'});
        },
        addStar() {
            this.$store.dispatch('mark', {
                dialogueIds: this.selectedDialogueIds,
                userId: this.$route.params.userId
            });
        },
        removeStar() {
            this.$store.dispatch('unmark', {
                dialogueIds: this.selectedDialogueIds,
                userId: this.$route.params.userId
            });
        },
        markAsUnread() {
            this.$store.dispatch('markAsUnread', {
                dialogueIds: this.selectedDialogueIds,
                userId: this.$route.params.userId
            });
        },
        markAsRead() {
            this.$store.dispatch('markAsRead', {
                dialogueIds: this.selectedDialogueIds,
                userId: this.$route.params.userId
            });
        },
        refresh() {
            this.$store.dispatch('fetchDialogues');
        },
        prevPage() {
            if (this.$store.state.page.selected > 0) {
                this.$store.commit('CHANGE_PAGE', false);
                this.$store.dispatch('fetchDialogues');
            }
        },
        nextPage() {
            this.$store.commit('CHANGE_PAGE', true);
            this.$store.dispatch('fetchDialogues');
        },
        statusSelected(option) {
            this.$store.dispatch('filterByStatus', {
                status: option
            });
        },
        sourceSelected(option) {
            this.$store.dispatch('filterBySource', {
                source: option
            });
            this.applyFilter();
        },
        respondingPersonSelected(option) {
            this.$store.dispatch('filterByRespondingPerson', {
                person: option
            });
        },
        languageSelected(option) {
            this.$store.dispatch('filterByLanguage', {
                language: option
            });
        },
        levelSelected(option) {
            this.$store.dispatch('filterByLevel', {
                level: option
            });
        },
        episodeSelected(option) {
            this.$store.dispatch('filterByEpisode', {
                episode: option
            });
        },
        tagFilterChanged(option) {
            this.$store.dispatch('filterByTag', {
                tagToAdd: option
            });
            this.applyFilter();
        },
        filterLabelClosed(label, filterName) {
            this.$store.dispatch(filterName, {toRemove: label});

            if (['filterByTag', 'filterBySource'].indexOf(filterName) !== -1) {
                this.applyFilter();
            }
        },
        withAnswersChanged() {
            this.$store.dispatch('changeWithAnswersMode');
        },
        latestVersionChanged() {
          this.$store.dispatch('changeLatestVersionMode');
        },
        toggleDateFilter() {
            this.hasDateFilter = !this.hasDateFilter;
        },
        toggleGroupGamesAndSources() {
            this.$store.dispatch('changeGroupGamesAndSources');
        },
        togglePayersOnlyMode() {
            this.$store.dispatch('changePayersOnlyMode');
        },
        showWriteToUserModal() {
            this.showWriteToUser = true;
            this.usersByName = [];
        },
        showFilterModal() {
            this.showFilter = true;
        },
        ruUserChanged() {
            this.isRUUser = !this.isRUUser;
        },
        applyFilter() {
            var dateFilter = this.hasDateFilter ? {
                from: moment(this.fromDate).startOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL),
                to: moment(this.toDate).endOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL)
            } : undefined;
            this.$store.dispatch('filterByDate', dateFilter);

            this.$store.dispatch('fetchDialogues');
            if (this.singleGameSelected) {
                this.$store.dispatch('fetchInboxCount', this.$route.params.folder.split(':')[1]);
            }
        },
        writeToUserGameChanged(game) {
            this.writeToUserGame = game;
        },
        closeRedirectForm() {
            this.writeToUserGame= undefined;
            this.writeToUserId= undefined;
            this.usersByName = [];
            this.isRUUser = false;
            this.showWriteToUser = false;
        },
        searchUserLink(userId) {
            return this.$router.resolve({
                name: 'UserCard', params: { folder: 'Inbox' + ":" + this.writeToUserGame, userId: userId}
            }).href;
        },
        redirectToUser() {
            if (!this.writeToUserGame || !this.writeToUserId) {
                alert("Specify game and ID");
                return;
            }

            var url = utils.getUserToolUrl(this.isRUUser, this.writeToUserGame);
            axios.get(url + "/search/" + encodeURIComponent(this.writeToUserId)).then(function (response) {
                if (Array.isArray(response.data) && response.data.length === 1) {
                    response.data = response.data[0];
                }

                if (Array.isArray(response.data)) {
                    this.usersByName = response.data;
                } else if (response.data === this.writeToUserId) {
                    var routerData = {
                        name: 'UserCard', params: {
                            folder: 'Inbox' + ":" + this.writeToUserGame,
                            userId: this.writeToUserId
                        }
                    };
                    this.writeToUserId = "";
                    this.showWriteToUser = false;
                    var link = this.$router.resolve(routerData).href;
                    if (this.isRUUser) {
                        link += "?ruserver=true";
                    }

                    window.open(link, '_blank');
                } else {
                    alert("User " + this.writeToUserId + " not found in " + this.writeToUserGame + " database");
                }
            }.bind(this));
        },
        cancelFilter() {
            this.$store.dispatch('changeWithAnswersMode', false);
            this.$store.dispatch('changeUnreadOnlyMode', false);
            this.$store.dispatch('filterByDate', {});

            this.$store.dispatch('filterByStatus', { clear: true});
            this.$store.dispatch('filterBySource', { clear: true});
            this.$store.dispatch('filterByRespondingPerson', { clear: true});
            this.$store.dispatch('filterByLanguage', { clear: true});
            this.$store.dispatch('filterByTag', { clear: true});
            this.$store.dispatch('filterByLevel', { clear: true});
            this.$store.dispatch('filterByEpisode', { clear: true});

            this.$store.dispatch('fetchDialogues');
            if (this.singleGameSelected) {
                this.$store.dispatch('fetchInboxCount', this.$route.params.folder.split(':')[1]);
            }
        }
    }
}
</script>

<style scoped>
    .sub-header {
        padding: 8px 30px 8px 70px;
        border-bottom: 1px solid #e5e5e5;
        height: 50px;
    }

    .paging {
        float: right;
        line-height: 32px;
    }

    .paging .split-btn {
        float: right;
        margin: 0 0 0 10px;
        line-height: 1.2;
    }

    .paging .btn .icon {
        opacity: 0.5;
    }

    .paging .btn:hover .icon {
        opacity: 0.9;
    }

    .paging .inactive {
        cursor: default;
    }

    .paging .inactive .icon {
        opacity: 0.3;
    }

    .paging .inactive:hover .icon {
        opacity: 0.3;
    }

    .checkbox-all {
        margin-top: 10px;
    }

    .checkbox-filters {
        display: inline-block;
        margin: -50px 10px 0 0;
        position: relative;
        left: 240px;
        bottom: 48px;
    }

    .back {
        display: inline-block;
    }

    .icons-container {
        display: inline-block;
    }

    .container {
        display: inline-block!important;
        margin-bottom: 10px;
    }

    .actionButton {
        display: inline-block;
        border-radius: 6px;
        border: none;
        height: 24px;
        width: 116px;
        margin: 4px;
        float: right;
        font-weight: bold;
    }

    .filter-block-caption {
        display: block;
        position: relative;
        margin: 10px 10px 10px 0px;
    }

    .modal-body .dropdown {
        position: relative;
        display: block;
    }

    .filter-tags {
        display: inline-block;
        width: 240px;
        margin: 5px;
    }

    .top-filter {
        display: inline-block;
        margin: 5px;
    }

    .top-filter-label {
        display: inline-block;
    }

    .date-filter-block {
        width: 220px;
        border-radius: 6px;
        height: 24px;
        margin-left: 250px;
        position: absolute;
    }

    .filter-block {
        max-width: 220px;
    }

    .v3dp__datepicker {
        margin: 10px 0;
    }

    .filter-desc {
        display: inline-block;
        margin-left: -8px;
        color: #B20000;
    }

    .filter-desc::before {
        content: '\002757';
    }

    .date-checkbox {
        display: inline-block;
        margin-bottom: 20px;
    }

    .container-for-users {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        height: 150px;
        border: 1px solid #ebebeb;
        width: calc(100% - 56px);
        padding: 10px;
    }


</style>
