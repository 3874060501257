<template>
<i :class="`icon icon-${name}`"></i>
</template>

<style scoped>
.icon {
    display: inline-block;
    background-size: 100%;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin: 0px 5px;
    z-index: 9999999;
}

.icon:hover {
    background-color: #e8ecec;
    transition: all 0.5s ease;
}

.icon-back {
    background-image: url(../../assets/icons/back.png);
    width: 30px;
}
.icon-back_light {
    background-image: url(../../assets/icons/back_light.png);
    width: 30px;
    height: 16px;
}

.icon-home {
    background-image: url(../../assets/icons/home.png);
}
.icon-home_dark {
    background-image: url(../../assets/icons/home_dark.png);
}

.icon-statistic {
    background-image: url(../../assets/icons/statistic.png);
    width: 20px;
    height: 17px;
}

.icon-versions {
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22white%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20stroke%3D%22none%22%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M2%2012c0%205.523%204.477%2010%2010%2010s10-4.477%2010-10S17.523%202%2012%202%202%206.477%202%2012%22%2F%3E%3Cpath%20d%3D%22M14%2014.25c0%20.414.336.75.75.75h1.25a1%201%200%200%200%201-1v-1a1%201%200%200%200-1-1h-1a1%201%200%200%201-1-1v-1a1%201%200%200%201%201-1h1.25a.75.75%200%200%201.75.75%22%2F%3E%3Cpath%20d%3D%22M7%209l2%206%202-6%22%2F%3E%3C%2Fsvg%3E);
    width: 26px;
    height: 25px;
    color: white;
}

.icon-tags_filter {
    background-image: url(../../assets/icons/tags_light.png);
    width: 18px;
    height: 18px;
}

.icon-archive {
    background-image: url(../../assets/icons/archive.png);
    width: 21px;
}
.icon-archive_light {
    background-image: url(../../assets/icons/archive_light.png);
    width: 19px;
    height: 18px;
}

.icon-restore {
    background-image: url(../../assets/icons/restore.png);
    height: 22px;
}
.icon-restore_light {
    background-image: url(../../assets/icons/restore_light.png);
    height: 22px;
}

.icon-present {
    background-image: url(../../assets/icons/present.png);
    width: 22px;
    height: 23px;
}

.icon-next {
    background-image: url(../../assets/icons/next.png);
    height: 16px;
    width: 10px;
}

.icon-prev {
    background-image: url(../../assets/icons/prev.png);
    height: 20px;
    width: 12px;
}

.icon-refresh {
    background-image: url(../../assets/icons/refresh.png);
}
.icon-refresh_light {
    background-image: url(../../assets/icons/refresh_light.png);
}

.icon-reply {
    background-image: url(../../assets/icons/reply.png);
}

.icon-search {
    background-image: url(../../assets/icons/search.png);
    margin: 5px;
}
.icon-search_light {
    background-image: url(../../assets/icons/search_light.png);
}

.icon-inbox {
    background-image: url(../../assets/icons/inbox.png);
    width: 22px;
    height: 17px;
}

.icon-inbox_en {
    background-image: url(../../assets/icons/inbox_en.png);
    width: 22px;
    height: 17px;
}

.icon-inbox_payers {
    background-image: url(../../assets/icons/inbox_payers.png);
    width: 22px;
    height: 17px;
}

.icon-sent {
    background-image: url(../../assets/icons/sent.png);
    width: 22px;
    height: 15px;
}

.icon-spam {
    background-image: url(../../assets/icons/spam.png);
    width: 17px;
    height: 19px;
}

.icon-star {
    background-image: url(../../assets/icons/star_grey.png);
    height: 19px;
}

.icon-star-on {
    background-image: url(../../assets/icons/star_on.png);
    height: 19px;
}

.icon-star-off {
    background-image: url(../../assets/icons/star_off.png);
}
.icon-star-off_light {
    background-image: url(../../assets/icons/star_off_light.png);
}

.icon-mark-read {
    background-image: url(../../assets/icons/mark_read.png);
    height: 23px;
}
.icon-mark-read_light {
    background-image: url(../../assets/icons/mark_read_light.png);
    height: 23px;
}

.icon-mark-unread {
    background-image: url(../../assets/icons/mark_unread.png);
    height: 23px;
}
.icon-mark-unread_light {
    background-image: url(../../assets/icons/mark_unread_light.png);
    height: 23px;
}

.icon-payer {
    background-image: url(../../assets/icons/payer.png);
    height: 20px;
    width: 10px;
}

.icon-down {
    background-image: url(../../assets/icons/down.png);
    width: 13px;
    height: 13px;
    margin-bottom: 6px;
    margin-right: -2px;
}

.icon-right {
    background-image: url(../../assets/icons/right.png);
    width: 13px;
    height: 13px;
    margin-bottom: 6px;
    margin-right: -2px;
}
.icon-right_light {
    background-image: url(../../assets/icons/right_light.png);
    width: 13px;
    height: 13px;
    margin-bottom: 6px;
    margin-right: -2px;
}

.icon-music-off {
    background-image: url(../../assets/icons/music_off.png);
    width: 25px;
    height: 25px;
}
.icon-music-on {
    background-image: url(../../assets/icons/music_on.png);
    width: 22px;
    height: 25px;
}
.icon-sound-on {
    background-image: url(../../assets/icons/sound_on.png);
    width: 23px;
    height: 22px;
}
.icon-sound-off {
    background-image: url(../../assets/icons/sound_off.png);
    width: 23px;
    height: 23px;
}

.icon-android {
    background-image: url(../../assets/sources/androidsocial.png);
}
.icon-ios {
    width: 17px;
    height: 21px;
    background-image: url(../../assets/sources/applesocial.png);
}
.icon-draugiem {
    background-image: url(../../assets/sources/draugiem.png);
}
.icon-facebook {
    background-image: url(../../assets/sources/facebook.png);
}
.icon-fotostrana {
    background-image: url(../../assets/sources/fotostrana.png);
}
.icon-mbga {
    background-image: url(../../assets/sources/mbga.png);
}
.icon-sp_mbga {
    background-image: url(../../assets/sources/sp_mbga.png);
}
.icon-mm {
    background-image: url(../../assets/sources/mm.png);
}
.icon-ok {
    background-image: url(../../assets/sources/ok.png);
}
.icon-vk {
    background-image: url(../../assets/sources/vk.png);
}

.icon-samsung {
  background-image: url(../../assets/sources/samsung.png);
}

.icon-ton {
  background-image: url(../../assets/sources/ton.png);
}

.icon-instant {
    background-image: url(../../assets/sources/instant.png);
    height: 18px;
    width: 19px;
}
.icon-microsoft {
    background-image: url(../../assets/sources/microsoft.png);
}
.icon-amazon {
    background-image: url(../../assets/sources/web_amazon.png);
}
.icon-xsolla {
    background-image: url(../../assets/sources/xsolla.png);
}
.icon-discord {
    background-image: url(../../assets/sources/discord.png);
}

.icon-gdcom {
    background-image: url(../../assets/sources/gdcom.png);
}
.icon-plinga {
    background-image: url(../../assets/sources/plinga.png);
}
.icon-yandex {
    background-image: url(../../assets/sources/yandex.png);
    width: 18px;
    height: 18px;
}
.icon-iyodo1 {
    background-image: url(../../assets/sources/yodo.png);
    width: 18px;
    height: 18px;
}
.icon-macos {
    background-image: url(../../assets/sources/macos.png);
}
.icon-kongregate {
  background-image: url(../../assets/sources/kongregate.png);
}
.icon-xiaomi {
  background-image: url(../../assets/sources/mi.png);
}
.icon-msstart {
  background-image: url(../../assets/sources/msn.png);
}

.icon-rustore {
    background-image: url(../../assets/sources/rustore.png);
}

.icon-mygames {
  background-image: url(../../assets/sources/mygames.png);
}

.icon-crazy {
    background-image: url(../../assets/sources/crazy.png);
}

.icon-cleverapps {
    background-image: url(../../assets/sources/cleverapps.png);
}

.icon-gesturecard {
    background-image: url(../../assets/gestures/gesturecard.png);
    width: 152px;
    height: 153px;
}
.icon-gesturecard:hover {
    background-color: transparent !important;
    transition: none;
    border-radius: unset;
}
.icon-postpone {
    background-image: url(../../assets/gestures/postopne.png);
    width: 119px;
    height: 119px;
}
.icon-postpone_active {
    background-image: url(../../assets/gestures/postopne_active.png);
    width: 119px;
    height: 119px;
}
.icon-postpone_active:hover {
    background-color: transparent !important;
    transition: none;
    border-radius: unset;
}
.icon-resolve {
    background-image: url(../../assets/gestures/resolve.png);
    width: 119px;
    height: 119px;
}
.icon-resolve_active {
    background-image: url(../../assets/gestures/resolve_active.png);
    width: 119px;
    height: 119px;
}
.icon-resolve_active:hover {
    background-color: transparent !important;
    transition: none;
    border-radius: unset;
}
.icon-skip {
    background-image: url(../../assets/gestures/skip.png);
    width: 119px;
    height: 119px;
}
.icon-skip_active {
    background-image: url(../../assets/gestures/skip_active.png);
    width: 119px;
    height: 119px;
}
.icon-skip_active:hover {
    background-color: transparent !important;
    transition: none;
    border-radius: unset;
}
.icon-default_avatar {
    background-image: url(../../assets/default_avatar.jpg);
    width: 50px;
    height: 50px;
}

.icon-payment_error {
  background-image: url(../../assets/payment/error.png);
  width: 25px;
  height: 25px;
}
.icon-payment_new {
  background-image: url(../../assets/payment/new.png);
  width: 25px;
  height: 25px;
}
.icon-payment_restore {
  background-image: url(../../assets/payment/restore.png);
  width: 25px;
  height: 25px;
}
.icon-payment_success {
  background-image: url(../../assets/payment/success.png);
  width: 25px;
  height: 25px;
}
.icon-payment_refund {
    background-image: url(../../assets/payment/refund.png);
    width: 25px;
    height: 25px;
}
.icon-payment_duplicate {
  background-image: url(../../assets/payment/duplicate.png);
  width: 25px;
  height: 25px;
}

.icon-price_kred {
    background-image: url(../../assets/payment/kred.png);
    width: 25px;
    height: 25px;
}
.icon-price_ok {
    background-image: url(../../assets/payment/ok.png);
    width: 25px;
    height: 25px;
}
.icon-price_yandex {
    background-image: url(../../assets/payment/yandex.png);
    width: 25px;
    height: 25px;
}

.icon-resource {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512 512" fill="currentColor"%3E%3Cpath d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2c0 0 0 0 0 0s0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336l0-35.4c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4l0 3.4 0 5.7 0 26.3zm32 0l0-32 0-25.9c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 44.2-86 80-192 80S0 476.2 0 432l0-35.4c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"/%3E%3C/svg%3E');
    width: 15px;
    height: 15px;
    margin: 0 0 0 5px;
}

.icon-overview {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512 512" fill="currentColor"%3E%3Cpath d="M32 32c17.7 0 32 14.3 32 32l0 336c0 8.8 7.2 16 16 16l400 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L80 480c-44.2 0-80-35.8-80-80L0 64C0 46.3 14.3 32 32 32zm96 96c0-17.7 14.3-32 32-32l192 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-192 0c-17.7 0-32-14.3-32-32zm32 64l128 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-128 0c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 96l256 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-256 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z"/%3E%3C/svg%3E');
    width: 15px;
    height: 15px;
    margin: 0 0 0 5px;
}

.icon-experiments {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="currentColor"%3E%3Cpath d="M288 0L160 0 128 0C110.3 0 96 14.3 96 32s14.3 32 32 32l0 132.8c0 11.8-3.3 23.5-9.5 33.5L10.3 406.2C3.6 417.2 0 429.7 0 442.6C0 480.9 31.1 512 69.4 512l309.2 0c38.3 0 69.4-31.1 69.4-69.4c0-12.8-3.6-25.4-10.3-36.4L329.5 230.4c-6.2-10.1-9.5-21.7-9.5-33.5L320 64c17.7 0 32-14.3 32-32s-14.3-32-32-32L288 0zM192 196.8L192 64l64 0 0 132.8c0 23.7 6.6 46.9 19 67.1L309.5 320l-171 0L173 263.9c12.4-20.2 19-43.4 19-67.1z"/%3E%3C/svg%3E');
    width: 15px;
    height: 15px;
    margin: 3px 0 4px 0
}

.icon-copy {
    width: 15px;
    height: 15px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="%23007bff" width="15" height="15"%3E%3Cpath fill-rule="evenodd" d="M15.988 3.012A2.25 2.25 0 0118 5.25v6.5A2.25 2.25 0 0115.75 14H13.5v-3.379a3 3 0 00-.879-2.121l-3.12-3.121a3 3 0 00-1.402-.791 2.252 2.252 0 011.913-1.576A2.25 2.25 0 0112.25 1h1.5a2.25 2.25 0 012.238 2.012zM11.5 3.25a.75.75 0 01.75-.75h1.5a.75.75 0 01.75.75v.25h-3v-.25z" clip-rule="evenodd"%3E%3C/path%3E%3Cpath d="M3.5 6A1.5 1.5 0 002 7.5v9A1.5 1.5 0 003.5 18h7a1.5 1.5 0 001.5-1.5v-5.879a1.5 1.5 0 00-.44-1.06L8.44 6.439A1.5 1.5 0 007.378 6H3.5z"%3E%3C/path%3E%3C/svg%3E');
}

.icon-adventure {background-image: url(../../assets/games/adventure.png);}
.icon-crocword {background-image: url(../../assets/games/crocword.png);}
.icon-differences {background-image: url(../../assets/games/differences.png);}
.icon-heroes {background-image: url(../../assets/games/heroes.png);}
.icon-magicwords {background-image: url(../../assets/games/magicwords.png);}
.icon-mergecraft {background-image: url(../../assets/games/mergecraft.png);}
.icon-olympics {background-image: url(../../assets/games/olympics.png);}
.icon-riddles {background-image: url(../../assets/games/riddles.png);}
.icon-runes {background-image: url(../../assets/games/runes.png);}
.icon-scramble {background-image: url(../../assets/games/scramble.png);}
.icon-tripeaks {background-image: url(../../assets/games/tripeaks.png);}
.icon-wondermerge {background-image: url(../../assets/games/wondermerge.png);}
.icon-wordsoup {background-image: url(../../assets/games/wordsoup.png);}
.icon-fairy {background-image: url(../../assets/games/fairy.png);}
.icon-spades {background-image: url(../../assets/games/spades.png);}
.icon-woodenblock {background-image: url(../../assets/games/woodenblock.png);}
.icon-tetris {background-image: url(../../assets/games/tetris.png);}
.icon-zenmatch {background-image: url(../../assets/games/zenmatch.png);}
.icon-tilehero {background-image: url(../../assets/games/tilehero.png);}
.icon-hustlemerge {background-image: url(../../assets/games/hustlemerge.png);}
.icon-garden {background-image: url(../../assets/games/garden.png);}
</style>

<script>
export default {
  name: 'icon',
  props: ['name']
}
</script>
