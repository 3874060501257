/**
* Created by Andrey Popov on 12/9/20.
*/

<template>
    <SidebarMenu :menu="menu" :collapsed="isMenuCollapsed" :hideToggle="false" :relative="true" :disableHover="false"
                 @update:collapsed="onToggleCollapse" @item-click="onMenuItemClick"/>
    <TopHeader/>
    <ToolPanel/>
    <perfect-scrollbar class="content">
        <table>
            <thead v-if="false">
                <tr>
                    <th class="checkbox"></th>
                    <th class="source"></th>
                    <th class="star"></th>
                    <th class="payer" v-if="!payerFolder"></th>
                    <th :class="{wide:key.code === 'message',small:key.small}" v-for="key in columns">
                        {{ key.header }}
                    </th>
                </tr>
            </thead>
            <tbody>
            <tr v-for="entry in dialogues">
                <td class="checkbox">
                    <CheckBox :selected="isSelected(entry)" :onChange="() => toggleSelected(entry)"/>
                </td>
                <td class="source">
                    <Icon :name="entry['source']" :title="entry['source']"/>
                </td>
                <td class="star">
                    <Icon v-if="starred(entry)" name="star-on" @click="toggleStar(entry)"/>
                    <Icon v-if="!starred(entry)" name="star-off" @click="toggleStar(entry)"/>
                </td>
                <td class="payer" v-if="!payerFolder">
                    <Icon v-if="entry['payerLevel'] > 0" name="payer" :title="entry['payerLevel']"/>
                </td>
                <td v-for="key in columns" @click="goToUserCard(entry, $event)"
                    :class="{date:key.code === 'postDate', wide:key.code === 'message', user:key.code === 'name', lang:key.code === 'language', unread:isUnread(entry) }">
                    <UserCell :entry="entry" v-if="key.code === 'name'"/>
                    <MessageCell :entry="entry" v-if="key.code === 'message'"/>
                    <img class="flag-icon" v-if="key.code === 'language'" :title='entry.language'
                         :src="getFlagUrl(entry.language)"/>
                    {{ formatCell(key.code, entry) }}
                </td>
                <td class="game" v-if="!singleGame">
                    <Icon v-if="entry['game']" :name="entry['game'].split('_')[0].toLowerCase()" :title="entry['game']"/>
                </td>
            </tr>
            </tbody>
        </table>
    </perfect-scrollbar>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>
</template>

<script>
    import moment from 'moment'
    import router from "./index";
    import utils from "@/utils.js";
    import config from "@/config.js";

    import CheckBox from '@/controls/checkbox.vue';
    import Icon from '@/controls/icon.vue';
    import DropDown from '@/controls/dropdown';
    import Label from '@/controls/label.vue';
    import Switch from '@/controls/switch.vue';
    import Modal from '@/controls/modal.vue';

    import UserCell from "@/components/dialogues/dialogueusercell.vue";
    import MessageCell from "@/components/dialogues/dialoguemessagecell.vue";
    import TopHeader from '@/components/topheader'
    import ToolPanel from '@/components/toolpanel'
    import SidebarMenu from "@/components/sidebar/sidebarmenu"

    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: 'DialoguesView',
        components: {
            CheckBox,
            Icon,
            UserCell,
            MessageCell,
            Loading,
            TopHeader,
            ToolPanel,
            SidebarMenu,
            DropDown,
            Label,
            Switch,
            Modal
        },
        beforeCreate() {
            if (!this.$store.getters.isAuthenticated) {
                this.$router.push({name: 'Login'});
            }
        },
        created() {
            if (this.$store.getters.isAuthenticated) {
                if (!this.$store.state.tags.all) {
                    this.$store.dispatch('fetchTags');
                }

                var folder = this.$route.params.folder;
                if (folder && folder.split(':')[0].indexOf('Inbox') === -1 || utils.hasQueryFilter()) {
                    this.$store.dispatch('fetchDialogues');
                } else {
                    this.$store.commit('DIALOGUES_FETCHED', []);
                }
            }
        },
        computed: {
            isLoading() {
                return this.$store.state.loading;
            },
            dialogues() {
                return this.$store.state.dialogues
            },
            columns() {
                var columns = [{
                    code: 'name',
                    header: 'User'
                }, {
                    code: 'message',
                    header: 'Preview'
                }, {
                    code: 'postDate',
                    header: 'Last update'
                }];

                if (!this.enFolder) {
                    columns.push({
                        code: 'language',
                        header: 'Lang'
                    });
                }

                return columns;
            },
            singleGame() {
                var games = this.$route.params.folder.split(':')[1].split(',');
                return games.length === 1 && games[0] !== 'all';
            },
            selectedDialogueIds() {
                return this.$store.state.selectedDialogueIds
            },
            query() {
                return this.$store.state.query;
            },
            payerFolder() {
                var folder = router.currentRoute.value.params.folder.split(':')[0];
                return folder.startsWith('$');
            },
            enFolder() {
                var folder = router.currentRoute.value.params.folder.split(':')[0];
                return folder.endsWith('_en');
            },
            isMenuCollapsed() {
                return this.$store.state.uiState.sidebarCollapsed;
            },
            menu() {
                var items = [{
                    title: 'Home',
                    icon: 'home',
                    noAnimation: true,
                    href: { name: 'ZenActivator'}
                }, {
                    title: 'Statistic',
                    icon: 'statistic',
                    noAnimation: true,
                    href: 'https://redash.cleverappssg.com/dashboard/crm-statistic',
                    external: true
                }, {
                    title: 'Versions',
                    icon: 'versions',
                    noAnimation: true,
                    href: { name: 'Versions'}
                }];

                items = items.concat(config.folders.map(folder => {
                    var isInbox = folder.name.indexOf('Inbox') !== -1

                    var menuItem = {
                        title: folder.name,
                        icon: folder.icon,
                        href: { name: 'Dialogues', params: {folder: folder.name + ":all"}}
                    }

                    if (isInbox) {
                        menuItem.child = config.games.map(game => {
                            return {
                                title: game,
                                icon: game,
                                href: { name: 'Dialogues', params: {folder: folder.name + ':' + game}}
                            }
                        });
                    }

                    return menuItem;
                }));

                return items;
            }
        },
        methods: {
            formatCell(code, entry) {
                if (code === 'postDate') {
                    return entry[code] ? moment(entry[code]).fromNow() : ""
                } else if (['name', 'language', 'message', 'game'].indexOf(code) !== -1) {
                    return '';
                } else if (code === 'message' && entry['messageMark'] && entry['messageMark'].indexOf('gift') !== -1) {
                    return 'Game administrators sent special present for You!';
                }
                return entry[code];
            },
            goToUserCard(dialogue, event) {
                var folder = this.$route.params.folder.split(':')[0];
                var routerData = {
                    name: 'UserCard', params: {
                        folder: folder + ":" + dialogue.game,
                        userId: dialogue.userId
                    }
                };

                if (event.ctrlKey) {
                    window.open(this.$router.resolve(routerData).href, '_blank');
                } else {
                    this.$router.push(routerData);
                }
            },
            isSelected(dialogue) {
                return this.selectedDialogueIds.indexOf(utils.createDialogueId(dialogue.game, dialogue.dialogueId)) > -1
            },
            toggleSelected(dialogue) {
                this.$store.commit('DIALOGUES_SELECT_DIALOGUE', utils.createDialogueId(dialogue.game, dialogue.dialogueId))
            },
            starred(dialogue) {
                return dialogue.starred === 1;
            },
            toggleStar(dialogue) {
                if (dialogue.starred === 1) {
                    this.$store.dispatch('unmark', {
                        dialogueIds: [utils.createDialogueId(dialogue.game, dialogue.dialogueId)]
                    });
                } else {
                    this.$store.dispatch('mark', {
                        dialogueIds: [utils.createDialogueId(dialogue.game, dialogue.dialogueId)]
                    });
                }
            },
            isUnread(entry) {
                if (entry.isResponse === 1) {
                    return false;
                }
                return entry.readByUser === 0;
            },
            getFlagUrl(lang) {
                var images = require.context('../../assets/flags', false, /\.svg$/);
                if (lang && lang.length > 1) {
                    return images('./' + lang + '.svg');
                }
            },
            onToggleCollapse(collapsed) {
                this.$store.commit('SET_UI_STATE', {
                    sidebarCollapsed: collapsed,
                    sidebarGameSelected: this.$store.state.uiState.sidebarGameSelected,
                    userProfileCollapsed: this.$store.state.uiState.userProfileCollapsed
                });
            },
            onMenuItemClick(event, item) {
                this.$store.commit('SET_UI_STATE', {
                    sidebarCollapsed: this.$store.state.uiState.sidebarCollapsed,
                    sidebarGameSelected: config.folders.map(folder => folder.name).indexOf(item.title) === -1,
                    userProfileCollapsed: this.$store.state.uiState.userProfileCollapsed
                });
            }
        },
        watch: {
            $route() {
                var folder = this.$route.params.folder;
                if (folder && folder.split(':')[0].indexOf('Inbox') === -1 && ['Statistic', 'ZenActivator'].indexOf(this.$route.name) === -1 &&
                    this.$route.params.userId === undefined && this.$store.getters.isAuthenticated) {
                    this.$store.dispatch('fetchDialogues');
                } else {
                    this.$store.commit('DIALOGUES_FETCHED', []);
                }
            }
        }
    }
</script>

<style scoped>
    table {
        margin-top: 10px;
        max-height: 90%;
        width: 100%;
        table-layout: fixed;
    }

    th {
        color: gray;
        border-bottom: 1px solid #e5e5e5;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    td {
        cursor: pointer;
        border-bottom: 1px solid #e5e5e5;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    td.unread {
        font-weight: bold;
    }

    th, td {
        padding: 2px;
        width: 14%;
    }

    th.checkbox, td.checkbox {
        width: 20px;
        padding: 2px 6px;
    }

    th.star, td.star,
    th.source, td.source {
        width: 24px;
        padding: 0;
    }

    th.payer, td.payer {
        width: 26px;
        padding: 0;
    }

    th.wide, td.wide {
        width: 60%;
    }

    th.user, td.user {
        width: 20%;
    }

    th.lang, td.lang {
        width: 3%;
        text-align: center;
    }

    th.game, td.game {
        width: 3%;
        text-align: center;
    }

    th.date, td.date {
        width: 12%;
        text-align: center;
    }

    .flag-icon {
        width: 20px;
        height: 20px;
    }
    .content {
        height: 92%
    }

</style>
