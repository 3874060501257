<template>
    <div class="card shadow-sm experiment-card">
        <div class="card-header d-flex justify-content-between align-items-center bg-light">
            <div class="d-flex align-items-center">
        <span class="me-2">
          <Icon name="experiments" />
        </span>
                <span class="fw-bold">Experiments</span>
                <span class="badge bg-primary ms-2">{{ activeExperiments.length }}</span>
            </div>
        </div>

        <div class="card-body">
            <div v-for="test in tests" :key="test.name" class="mb-3 border-bottom pb-2">
                <h6 class="fw-bold mb-1">{{ formatExperimentName(test.name) }}</h6>
                <p v-if="forPage" class="text-muted small">{{ formatExperimentData(test) }}</p>
                <div class="d-flex justify-content-between align-items-center">
                    <span class="badge" :class="statusClass(test.status)">{{ test.status }}</span>
                    <div v-if="!forPage" class="text-muted small">
                        <span>Variant:</span> <span class="fw-bold">{{ test.group }}</span>
                    </div>
                    <div v-if="forPage" class="text-muted small">
                        <span>Variants:</span> <span class="fw-bold">{{ test.groups }}</span>
                    </div>
                </div>
                <div v-if="!forPage && testStartDate(test) !== undefined" class="text-muted small">from {{ testStartDate(test) }}</div>
                <a :href="generateExperimentLink(test)" target="_blank" class="small text-decoration-none">View experiment</a>
            </div>
        </div>

        <div v-if="!forPage" class="card-footer text-center bg-light small">
            View more items at <a href="#/experiments" class="fw-bold text-decoration-none">the experiments page!</a>
        </div>
    </div>
</template>

<script>
import Icon from '@/controls/icon.vue';
import utils from "@/utils.js";

export default {
    name: "ExperimentCard",
    components: { Icon },
    props: {
        tests: { type: Array, required: true },
        forPage: { type: Boolean, default: false }
    },
    computed: {
        activeExperiments() {
            return this.tests.filter(t => t.status === "live");
        },
        userCard() {
            return this.$store.state.userCard ? this.$store.state.userCard : {};
        },
        gameName() {
            return this.$route.params.folder.split(':')[1];
        }
    },
    methods: {
        formatExperimentName(name) {
            return name.charAt(0).toUpperCase() + name.slice(1).replace(/([A-Z])/g, " $1");
        },
        formatExperimentData(data) {
            if (!data || Object.keys(data).length === 0) {
                return "No additional information";
            }

            let formatted = [];
            for (const [key, value] of Object.entries(data)) {
                if (["status", "id", "groups", "group", "name", "groupbysource"].includes(key.toLowerCase())) {
                    continue;
                }

                if (key.toLowerCase() === "available" && typeof value === "object") {
                    let availableText = "Available";
                    var hasAvailable = false;
                    if (value.level) {
                        availableText += ` level "${value.level}" `;
                        hasAvailable = true;
                    }
                    if (value.source) {
                        availableText += ` source "${Array.isArray(value.source) ? value.source.join('", "') : value.source}" `;
                        hasAvailable = true;
                    }
                    if (value.feature) {
                        availableText += ` feature "${value.feature}" `;
                        hasAvailable = true;
                    }
                    if (value.registeredDate) {
                        availableText += ` reg. after "${value.registeredDate}" `;
                        hasAvailable = true;
                    }
                    if (Array.isArray(value.projectName) && value.projectName.length > 0) {
                        availableText += ` in "${value.projectName.join('", "')}"`;
                        hasAvailable = true;
                    }
                    if (hasAvailable) {
                        formatted.push(availableText);
                    }
                } else if (Array.isArray(value)) {
                    formatted.push(`${this.capitalize(key)}: ${value.join(", ")}`);
                } else if (typeof value === "boolean") {
                    formatted.push(`${this.capitalize(key)}: ${value ? "Enabled" : "Disabled"}`);
                } else if (typeof value === "number") {
                    formatted.push(`${this.capitalize(key)}: ${value}`);
                } else {
                    formatted.push(`${this.capitalize(key)}: ${value}`);
                }
            }
            return formatted.join(" | ");
        },
        generateExperimentLink(test) {
            var baseLink = "https://redash.cleverappssg.com/dashboard/metrics-abtest-starchest?p_abtest=" + encodeURIComponent(test.name);
            if (test.gameName) {
                baseLink += "&p_project=" + test.gameName;
            } else if (test.available && test.available.projectName) {
                baseLink += "&p_project=" + test.available.projectName[0];
            }
            return baseLink;
        },
        capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        statusClass(status) {
            return {
                'bg-warning': status === 'draft',
                'bg-success': status === 'live',
                'bg-primary': status === 'adopted',
                'bg-danger': status === 'reverted'
            };
        },
        testStartDate(test) {
            var available = test.available;
            if (available && available[this.gameName]) {
                available = available[this.gameName];
            }

            if (available && available.registeredDate) {
                return utils.formatDay(Math.max(this.userCard.registered, Date.parse(available.registeredDate), Date.parse(test.start) || 0));
            }
        }
    }
};
</script>

<style scoped>
.experiment-card {
    max-width: 500px;
    margin: 0 0 10px 10px;
}
</style>
