/**
 * Created by Andrey Popov on 12/16/20.
 */

const localhostGames = ["mergecraft"];
const productionGames = ["riddles", "heroes", "runes", "adventure", "wordsoup", "scramble", "crocword", "olympics", "tripeaks",
    "differences", "mergecraft", "magicwords", "wondermerge", "fairy", "spades", "woodenblock", "zenmatch", "hustlemerge"];
const stagingGames = ["mergecraft", "wondermerge", "fairy", "tripeaks", "crocword", "riddles", "hustlemerge"];

const folders = [{
    name: "$Inbox",
    icon: "inbox_payers"
}, {
    name: "Inbox",
    icon: "inbox"
}, {
    name: "Spam",
    icon: "spam"
}, {
    name: "Starred",
    icon: "star-on"
}];

const infoTabs = [
    { title: "Profile", value: "profile" },
    { title: "Game state", value: "gamestate" },
    { title: "Snapshot", value: "snapshot" },
    { title: "Payments", value: "payments" },
    { title: "Subs", value: "subscriptions" }];

const languages = ["de", "en", "ru", "es", "fr", "it", "ja", "nl", "pt", "ar", "ko", "tr", "zh", "pl", "lv"];

const sources = ["amazon", "android", "draugiem", "facebook", "fotostrana", "instant", "ios", "mbga",
    "sp_mbga", "mm", "ok", "vk", "gdcom", "coolmath", "plng", "plinga", "xsolla", "cleverapps", "yandex",
    "iyodo1", "microsoft", "macos", "kongregate", "msstart", "xiaomi", "mygames", "crazy", "samsung", "ton", "rustore", "discord"];

const respondingPersons = ["me", "Anna", "Mikhail", "Anastasia", "Vsevolod", "Bogdan", "Dmitriy", "Alexandrina",
    "Maria Naumova", "Artem Vinokurov", "Denis", "Sergei", "Natalia Sadovaya", "Arseniy Morozov", "Viktoriya", "Alena"];

const priorities = {
    high: {
        games: ["mergecraft", "wondermerge", "fairy", "hustlemerge", "riddles", "zenmatch", "tripeaks"],
        sources: ["instant", "ok", "microsoft", "msstart"]
    },
    medium: {
        games: ["heroes", "scramble", "woodenblock"],
        sources: ["yandex", "vk", "android", "facebook", "ios", "gdcom", "crazy", "samsung", "discord"]
    },
    low: {
        games: ["wordsoup", "crocword", "olympics", "magicwords", "differences", "runes", "adventure"],
        sources: ["mbga", "draugiem", "rustore", "mygames", "fotostrana", "cleverapps", "mm", "amazon", "macos", "ton"]
    }
};

export default {
    games: process.env.VUE_APP_MODE === "production" && productionGames || process.env.VUE_APP_MODE === "staging" && stagingGames || localhostGames,
    apiUrl: process.env.VUE_APP_MODE === "production" && "https://crm.cleverappssg.com" || process.env.VUE_APP_MODE === "staging"
        && "https://crm-staging.labsystech.ru" || /* "http://localhost:3205" */ "https://crm-staging.labsystech.ru",
    userToolUrl: process.env.VUE_APP_MODE === "production" ? "https://crm.labsystech.ru/{game}{server}" : "https://{game}-staging.labsystech.ru/crm-staging",
    folders,
    sources,
    priorities,
    respondingPersons,
    languages,
    infoTabs
};
