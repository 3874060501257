<template>
    <div class="p-3">
        <h5 class="mb-2">Offers</h5>
        <div class="d-flex flex-wrap gap-2">
            <div
                v-for="(offer, index) in offers"
                :key="index"
                class="offer-card text-center p-3 rounded shadow-sm"
                :class="getOfferStatusClass(offer)"
                :title="`Type: ${offer.id} - ${offer.name}`"
                style="pointer-events: none;">
                <img :src="getOfferImage(offer.name)" class="offer-image mb-2" :alt="offer.name" />
                <div :title="offer.id" class="fw-bold fs-6">{{ formatName(offer.name) }}</div>
                <small v-if="offer.stage" :title="offer.stage" class="d-block">Stage {{ offer.stage }}</small>
                <small v-if="offer.started" class="d-block">From {{ formatDate(offer.started) }}</small>
                <small v-if="offer.finished" class="d-block">To {{ formatDate(offer.finished) }}</small>
                <small v-if="offer.lastRewardTime" class="d-block">Reward {{ formatDate(offer.lastRewardTime) }}</small>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import utils from "@/utils.js";

export default {
    name: "OffersProgress",
    props: {
        offers: {
            type: Array,
            required: true
        }
    },
    methods: {
        formatDate(timestamp) {
            return moment(timestamp).format("DD MMM YYYY");
        },
        getOfferImage(name) {
            return utils.getOfferUrl(name);
        },
        formatName(name) {
            return name && name.charAt(0).toUpperCase() + name.slice(1);
        },
        getOfferStatusClass(offer) {
            if (offer.started && !offer.lastRemoved) return "bg-primary text-white active-offer";
            if (offer.lastRemoved) return "bg-secondary text-white completed-offer";
            return "bg-dark text-light pending-offer";
        }
    }
};
</script>

<style scoped>
.offer-card {
    width: 166px;
    text-align: center;
    transition: transform 0.2s ease-in-out;
    border-radius: 12px;
    padding: 8px;
}

.offer-image {
    width: 134px;
    height: 134px;
    object-fit: contain;
}

.active-offer {
    background-color: #0d6efd;
    color: white;
}
.completed-offer {
    background-color: #6c757d;
    color: white;
    opacity: 0.7;
}
.pending-offer {
    background-color: #343a40;
    color: white;
    opacity: 0.5;
}
</style>
