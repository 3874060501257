<template>
    <nav class="tabs" :class="tabs">
        <button
            type="button"
            v-for="tab in tabs"
            :ref="tab.value"
            :key="tab.title"
            :class="[ { 'tabs_item_active' : tab.value === currentTab },
              {'tabs_item_active': tab.value === currentTab}, 'tabs_item']"
            :disabled="tab.disabled || false"
            @click="handleClick(tab.value)"
            v-html="tab.title"
        />
        <slot name="extra-items">
        </slot>
    </nav>
</template>

<script>
export default {
    name: 'tabs',
    props: {
        currentTab: {
            type: String,
            required: true,
        },
        tabs: {
            type: Array,
            required: true,
        }
    },
    watch: {
        currentTab(newCurrentTab) {
            if (this.newTab === newCurrentTab) {
                return;
            }
            this.moveActiveLine(newCurrentTab);
            this.newTab = newCurrentTab;
        },
        updated() {
            this.moveActiveLine(this.currentTab);
        },
    },
    data() {
        return {
            newTab: ''
        };
    },
    methods: {
        handleClick(value) {
            this.$emit('onClick', value);
            this.moveActiveLine(value);
            this.newTab = value;
        },
        moveActiveLine(newValue) {
            if (!this.currentTab && !this.$refs || !this.$refs[newValue] || !this.$refs[newValue][0]) {
                return;
            }
            const element = this.$refs[newValue][0];
            this.activeLineWidth = element.clientWidth;
            this.activeLineOffset = element.offsetLeft;
        },
    },
    mounted() {
        this.moveActiveLine(this.currentTab);
        this.newTab = this.currentTab;
    },
};
</script>

<style scoped>

.tabs {
    position: relative;
    margin-left: 10px;
    background-color: #ffffff;
    width: 98%;
    box-shadow: 0px -1px 7px 1px rgba(0, 0, 0, 0.2);
    border-radius:  0 0 8px 8px;
}

.tabs_item {
    display: inline-block;
    font-size: 18px;
    margin: 5px 2px;
    letter-spacing: 0.8px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.25s;

    color: rgb(45 144 220);
    font-weight: 600;
    padding: .5rem 1rem;
    border-radius: .25rem;
    border: none;
    background-color: #ffffff;
}

.tabs_item_active {
    color: rgb(255 255 255) !important;
    font-weight: 600;
    background-color: rgb(45 144 220);
}

.tabs_item:hover {
    background-color: rgb(212 212 212);
    color: rgb(36 115 176);
    font-weight: 600;
}

.tabs_item_active:hover {
    color: rgb(255 255 255);
    background-color: rgb(45 144 220);
}

.tabs_item:first-child {
    margin-left: 4px;
}

.tabs_item:last-child {
    margin-right: 0;
}

</style>