<template>
    <SidebarMenu :menu="menu" :collapsed="true" :hideToggle="true" :relative="false" />
    <perfect-scrollbar class="content">
        <div>
            <ExperimentCard :tests="abtests" :forPage="true" />
        </div>
    </perfect-scrollbar>
</template>

<script>

import SidebarMenu from "@/components/sidebar/sidebarmenu";
import Icon from "../controls/icon.vue";
import ExperimentCard from "../components/library/experimentcard.vue";

export default {
    name: "Experiments",
    components: {
        ExperimentCard,
        Icon,
        SidebarMenu
    },
    beforeCreate() {
        if (!this.$store.getters.isAuthenticated) {
            this.$router.push({ name: 'Login' });
        }
    },
    created() {
        this.$store.dispatch("fetchConfigs", "mergecraft");
    },
    computed: {
        menu() {
            return [{
                title: 'Home',
                icon: 'home',
                noAnimation: true,
                href: { name: 'ZenActivator' }
            }, {
                title: 'Search page',
                icon: 'search_light',
                noAnimation: true,
                action: function () {
                    this.$router.push({ name: 'Dialogues', params: { folder: "Inbox:all" } });
                }.bind(this)
            }];
        },
        abtests() {
            var names = Object.keys(this.$store.state.configs);
            if (names.length === 0) {
                return [];
            }
            var configData = (this.$store.state.configs[names[0]] || {}).ABExperiments || {};

            const statusOrder = { live: 1, draft: 2,  adopted: 3, reverted: 4 };
            configData = Object.entries(configData)
                .sort(([, a], [, b]) => (statusOrder[a.status] || 5) - (statusOrder[b.status] || 5))
                .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

            return Object.keys(configData).filter((e) => e !== "remote").map(function (experimentName) {
                var result = Object.assign({}, configData[experimentName]);
                result.name = experimentName;
                return result;
            }.bind(this));
        }
    }
};
</script>

<style scoped>
.caption {
    font-size: 24px;
    font-weight: 700;
    height: auto;
    line-height: 32px;
    margin: 20px 20px 20px 80px;
}

.content {
    margin: 10px 10px 20px 80px;
    padding-bottom: 20px;
}
</style>
