<template>
    <div class="card shadow-sm player-card">
        <div class="card-header d-flex justify-content-between align-items-center bg-light">
            <div class="d-flex align-items-center">
                <div :title="visitedAgo" class="status-circle me-2" :class="onlineStatus"></div>
                <img v-if="data.avatar" :src="data.avatar" class="rounded-circle avatar me-2" alt="avatar" />
                <Icon v-if="!data.avatar" class="avatar me-2" name="default_avatar" />
                <h5 class="m-0">{{ data.name }}</h5>
                <Icon v-if="data.source" :name="data.source" :title="data.source" class="ms-2" />
                <Icon v-if="data.payerLevel" :title="'Payer level: ' + data.payerLevel" name="inbox_payers" class="ms-2" />
            </div>
            <div class="d-flex align-items-center">
                <span class="text-muted small">ID: {{ data.id }}</span>
                <Icon @click="copyId" name="copy" title="Copy ID" class="ms-2" />
            </div>
        </div>
        <div class="card-body py-2">
            <div class="row g-2">
                <div class="col-md-6">
                    <PropertiesTable header="Resources" headerIcon="resource" :properties="resources" />
                </div>
                <div class="col-md-6">
                    <PropertiesTable header="Overview" headerIcon="overview" :properties="overview" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/controls/icon.vue';
import PropertiesTable from "@/components/library/propertiestable.vue";
import moment from "moment/moment";

export default {
    name: "PlayerCard",
    components: { PropertiesTable, Icon },
    props: {
        data: { type: Object, required: true },
        level: { type: String, default: "" },
        levelLink: { type: String, default: "#" }
    },
    computed: {
        resources() {
            return [
                { name: "Level", value: this.level, type: "link", link: this.levelLink },
                { name: "Exp", value: this.data.exp },
                { name: "Lives/Energy", value: this.data.lives ? this.data.lives.a : "" },
                { name: "Hard", value: this.data.gold },
                { name: "Soft", value: this.data.soft }
            ].filter(item => item.value !== undefined);
        },
        overview() {
            return [
                { name: "Joined", type: "date", value: this.data.registered },
                { name: "Last Login", type: "date", value: this.data.visited },
                { name: "Country", type: "flag", value: this.data.country},
                { name: "Language", type: "lang", value: this.data.language },
                { name: "Version", value: this.data.version },
                { name: "Ids history", value: this.data.userIdsHistory },
                { name: 'Channel', value: this.data.channel},
                { name: 'Session', value: this.data.session},
                { name: 'Screen', value: this.data.screenWidth ? this.data.screenWidth + "x" + this.data.screenHeight : undefined},
                { name: 'Off', value: this.data.off},
                { name: 'Cheater', value: this.data.cheater},
                { name: 'Device/browser/OS', value: this.data.useragent ? this.data.useragent.browser + " " + this.data.useragent.version : undefined }
            ].filter(item => item.value !== undefined);
        },
        visitedAgo() {
            if (!this.data || !this.data.visited) {
                return;
            }
            return 'visited ' + moment(this.data.visited).fromNow();
        },
        onlineStatus() {
            if (!this.data.visited) {
                return 'bg-light';
            }
            var lastVisitedInteral = (new Date() - new Date(this.data.visited))/60000;
            if (lastVisitedInteral < 15) {
                return 'bg-success';
            } else if (lastVisitedInteral < 120) {
                return 'bg-warning';
            } else {
                return 'bg-secondary';
            }
        }
    },
    methods: {
        copyId() {
            navigator.clipboard.writeText(this.data.id).then(() => {
                console.log("ID copied");
            });
        }
    }
};
</script>

<style scoped>
.player-card {
    width: 700px;
    margin: 0 0 10px 10px;
}
.avatar {
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.status-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}
.card-body {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
</style>