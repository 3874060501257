<template>
    <div class="p-3">
        <h5 class="mb-2">Game Locations</h5>
        <div class="d-flex flex-wrap gap-2">
            <div
                v-for="(location, name) in sortedLocations"
                :key="name"
                class="location-card text-center p-2 rounded"
                :class="getLocationStatusClass(location)"
                :style="location.finished || location.fn ? 'pointer-events: none; opacity: 0.6;' : ''">
                <img :src="getLocationImage(name)" class="location-image mb-2" :alt="name" />
                <div class="fw-bold">{{ formatLocationName(name) }}</div>
                <small v-if="location.started || location.st" class="d-block">Started {{ formatDate(location.started || location.st) }}</small>
                <small v-if="location.finished || location.fn" class="d-block">Finished {{ formatDate(location.finished || location.fn) }}</small>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import utils from "@/utils.js";

export default {
    name: "GameLocationsProgress",
    props: {
        locations: {
            type: Object,
            required: true
        }
    },
    methods: {
        getLocationImage(name) {
            return utils.getLocationUrl(name);
        },
        formatLocationName(name) {
            return name.charAt(0).toUpperCase() + name.slice(1);
        },
        getLocationStatusClass(location) {
            if (location.started || location.st) return "bg-primary text-white active-location";
            if (location.finished || location.fn) return "bg-secondary text-light completed-location";
            return "bg-dark text-white locked-location";
        },
        formatDate(timestamp) {
            return moment(timestamp).format("DD MMM YYYY");
        }
    },
    computed: {
        sortedLocations() {
            return Object.entries(this.locations)
                .sort((a, b) => {
                    const locA = a[1];
                    const locB = b[1];

                    if ((locB.started || locB.st || 0) !== (locA.started || locA.st || 0)) {
                        return (locB.started || locB.st || 0) - (locA.started || locA.st || 0);
                    }

                    return (locB.finished || locB.fn || 0) - (locA.finished || locA.fn || 0);
                })
                .reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                }, {});
        }
    }
};
</script>

<style scoped>
.location-card {
    width: 166px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s;
}
.location-image {
    width: 134px;
    height: 166px;
    object-fit: contain;
}
.active-location {
    border: 2px solid var(--bs-primary);
}
.completed-location {
    opacity: 0.6;
}
.locked-location {
    opacity: 0.3;
}
</style>